<!-- Container-fluid starts-->


<div class="row">
    <div class="col-12">
        <div class="card shadow">
            <div class="card-body">
                <div class="container">
                    <mat-stepper class="example-stepper" [orientation]="(stepperOrientation | async)!" [linear]="true"
                        [selectedIndex]="selectedStep" #stepper>
                        <mat-step [stepControl]="firstFormGroup" *ngIf="showPreviousSteps">
                            <div>
                                <cdk-accordion class="example-accordion" style="max-width: 100%;">
                                    <cdk-accordion-item #accordionItem1="cdkAccordionItem"
                                        class="example-accordion-item" role="region" tabindex="0"
                                        id="accordion-header-1" [expanded]="true"
                                        [attr.aria-expanded]="accordionItem1.expanded" aria-controls="accordion-body-1">
                                        <div class="example-accordion-item-header fontArial" (click)="accordionItem1.toggle()">
                                            Estudio de Mercado
                                            <span class="example-accordion-item-description">
                                                <mat-icon>
                                                    {{ accordionItem1.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                                                </mat-icon>
                                            </span>
                                        </div>
                                        <div class="example-accordion-item-body mercado" role="region"
                                            [style.display]="accordionItem1.expanded ? '' : 'none'"
                                            id="accordion-body-1" aria-labelledby="accordion-header-1">
                                            <div class="mt-4 bloque-item">
                                                <mat-tab-group #tabGroup>
                                                    <mat-tab label="Introducir datos">
                                                        <form [formGroup]="firstFormGroup"
                                                            (ngSubmit)="searchIAmercado()">
                                                            <ng-template matStepLabel
                                                                class="fontArial">Mercado</ng-template>
                                                            <div class="row" style="margin: 1rem;">
                                                                <div class="col-md-6 ">
                                                                    <p class="fontArial mb-3" style="margin: 0; ">
                                                                        Utilizando inteligencia artificial vamos a investigar tu
                                                                        competencia. Por favor ingresa los siguientes datos:
                                                                    </p>
                                                                    <mat-form-field appearance="outline"
                                                                        class="full-width mb-3">
                                                                        <mat-label>¿Qué perfil desea buscar?</mat-label>
                                                                        <select matNativeControl
                                                                            formControlName="select" required
                                                                            (change)="selectorOption($event)">
                                                                            <option value="Empresas">Empresas</option>
                                                                            <option value="Coaches">Coaches</option>
                                                                            <option value="Marcas personales">Marcas
                                                                                personales</option>
                                                                            <option value="Influencers">Influencers
                                                                            </option>
                                                                            <option value="Artistas">Artistas</option>
                                                                        </select>
                                                                    </mat-form-field>

                                                                    <mat-form-field appearance="outline"
                                                                        class="full-width mb-3">
                                                                        <mat-label>¿Cuál tema desea buscar?</mat-label>
                                                                        <input matInput type="text"
                                                                            formControlName="tema" required
                                                                            aria-describedby="addon-wrapping"
                                                                            (input)="updateInputValue($event, 'tema')">
                                                                    </mat-form-field>

                                                                    <!-- <h4 style="margin-top: 1rem;">Ubicación</h4> -->

                                                                    <mat-form-field appearance="outline"
                                                                        class="full-width mb-3">
                                                                        <mat-label>Ubicación</mat-label>
                                                                        <input matInput type="text"
                                                                            formControlName="ubicacion" required
                                                                            aria-describedby="addon-wrapping"
                                                                            (input)="updateInputValue($event, 'ubicacion')">
                                                                    </mat-form-field>


                                                                </div>


                                                                <!-- <div class="form-group"> -->
                                                                <div style="justify-content: end; margin-top: 1rem;">
                                                                    <button *ngIf="!existeContenido"
                                                                        [disabled]="spinnerEstudioMerca || generarMercado "
                                                                        type="submit"
                                                                        class="btn btn-primary align-items"
                                                                        [disabled]="firstFormGroup.invalid">
                                                                        <!-- <mat-spinner class="custom-spinner" ></mat-spinner> -->
                                                                        {{ !spinnerEstudioMerca ? 'Investigar competencia' :
                                                                        'Investigando competencia' }}
                                                                        <div *ngIf="spinnerEstudioMerca"
                                                                            style="margin-left: 1rem;"
                                                                            class="spinner-border" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </button>
                                                                </div>

                                                                <!-- </div> -->
                                                                <!--
                                                                <div class="col-md-4 columnas" >
            
                                                                    <div class="textarea-container" style="height: 75%;" >
                                                                        <textarea id="promptChatGPT" style="height: 100%;" class="form-control textArea" [(value)]="promptTxt"
                                                                            rows="4" placeholder="Prompt" readonly ></textarea>
                                                                        <button style="padding: 3px; "
                                                                            class="btn btn-outline-secondary copy-button" type="button"
                                                                            (click)="copyToClipboard()">
                                                                            <i style="margin: 0;" class="fa fa-copy"></i>
                                                                        </button>
                                                                        <p>1.- Copia esto y pegalo en <a href="https://chatgpt.com/">chatGPT</a></p>
            
                                                                    </div>
            
                                                                </div> -->
                                                            </div>
                                                            <div>

                                                            </div>

                                                        </form>


                                                    </mat-tab>
                                                    <mat-tab label="Respuesta">
                                                        <div [innerHTML]="respuestaMerca" class="tablaMerca">


                                                            <!-- <div class="form-group ">
                                                            <span>2.- Genera una respuesta con IA</span>
                                                            <ckeditor [editor]="Editor"
                                                            [data]="typedText">
                                                            </ckeditor>
                                                            </div> -->
                                                            <!-- <textarea [(value)]="typedText" style="height: 91%; width: 100%;"></textarea> -->
                                                        </div>

                                                        <div *ngIf="botonGuardarMerca && !existeContenido"
                                                            class="form-group">
                                                            <div style="justify-content: end; margin-top: 1rem;">
                                                                <button type="submit" class="btn btn-primary"
                                                                    (click)="guardarMercado()"
                                                                    [disabled]="firstFormGroup.invalid || guardarEstudio">Guardar</button><!--mat-button matStepperNext-->
                                                            </div>
                                                        </div>
                                                        <div *ngIf="existeContenido" class="form-group">
                                                            <div style="justify-content: end; margin-top: 1rem;">
                                                                <button type="submit" class="btn btn-primary"
                                                                    (click)="continuarMercado()">Continuar</button><!--mat-button matStepperNext-->
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!botonGuardarMerca" style="padding: 2rem;">
                                                            <span>** Te Falta generar una respuesta**</span>
                                                        </div>
                                                    </mat-tab>

                                                </mat-tab-group>


                                            </div>

                                        </div>
                                    </cdk-accordion-item>

                                    <cdk-accordion-item #accordionItem2="cdkAccordionItem"
                                        class="example-accordion-item" role="region" tabindex="0"
                                        id="accordion-header-2" [attr.aria-expanded]="accordionItem2.expanded"
                                        aria-controls="accordion-body-2">
                                        <div class="example-accordion-item-header fontArial" (click)="accordionItem2.toggle()">
                                            Investiga quién es tu competencía
                                            <span class="example-accordion-item-description">
                                                <mat-icon>
                                                    {{ accordionItem2.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                                                </mat-icon>
                                            </span>
                                        </div>
                                        <div class="example-accordion-item-body" role="region"
                                            [style.display]="accordionItem2.expanded ? '' : 'none'"
                                            id="accordion-body-2" aria-labelledby="accordion-header-2">

                                            <div class="mt-2 bloque-item" *ngIf="banderaPasoCompet">
                                                <div>
                                                    <form [formGroup]="estudioMerca" (ngSubmit)="estudioCompetencia()">
                                                        <div class="row">

                                                            <div>
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <mat-form-field appearance="outline"
                                                                            class="mb-3">
                                                                            <mat-label>Pega la URL del sitio web de tu
                                                                                competencia</mat-label>
                                                                            <input matInput type="text"
                                                                                formControlName="website1" required
                                                                                aria-describedby="addon-wrapping">
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div style="margin-bottom: 1rem;">
                                                            <button *ngIf="generarCompetencia && !continuarCompetencia"
                                                                [disabled]="spinnerCompetnecia || generarRespuesta "
                                                                type="submit" class="btn btn-primary align-items"
                                                                [disabled]="estudioMerca.invalid">
                                                                {{ !spinnerCompetnecia ? 'Investigar competencia' :
                                                                'Analizando competencia'}}
                                                                <div *ngIf="spinnerCompetnecia"
                                                                    style="margin-left: 1rem;" class="spinner-border"
                                                                    role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            </button>



                                                        </div>
                                                        <div class="form-group" style="height: 10rem;">



                                                            <!-- <ckeditor [editor]="Editor"  [data]="typedTextCompetencia" >
                                                                    </ckeditor>	 -->

                                                            <textarea *ngIf="respuestaCompetencia != undefined"
                                                                [(value)]="respuestaCompetencia"
                                                                style="height: 100%; width: 100%;"></textarea>


                                                            <!-- <div *ngIf="respuestaCompetencia == undefined">
                                                                        <span>** No se ha generado una respuesta**</span>
                                                                    </div> -->
                                                        </div>

                                                        <div
                                                            style="display: flex; margin-top: 1rem; justify-content: space-between;">



                                                            <!-- <div class="form-group"> -->
                                                            <!-- <div style="justify-content: end; margin-top: 1rem;"> -->

                                                            <button
                                                                *ngIf="!continuarCompetencia && respuestaCompetencia != undefined"
                                                                type="submit" class="btn btn-primary" mat-button
                                                                matStepperNext (click)="guardarCompetencia()"
                                                                [disabled]="estudioMerca.invalid || guardarRespuestaCompetencia">Guardar
                                                                y Continuar</button><!--mat-button matStepperNext-->
                                                            <!-- <button *ngIf="(continuar || !generarRespuesta) && botonesGuardaryContinuar"  class="btn btn-primary"  matStepperNext  [disabled]="estudioMerca.invalid " >Continuar</button> -->

                                                            <button *ngIf="continuarCompetencia" type="button"
                                                                mat-button matStepperNext
                                                                class="btn btn-primary align-items"
                                                                [disabled]="estudioMerca.invalid">
                                                                Continuar

                                                            </button>
                                                            <!-- </div> -->
                                                            <!-- </div> -->



                                                        </div>
                                                    </form>
                                                    <!-- <form [formGroup]="formGuardar" (ngSubmit)="enviarResChatGpt()"> -->
                                                    <!--
                                                            <div class="form-group">
                                                                <div style="justify-content: end; margin-top: 1rem;">
                                                                    <button (click)="notification()" type="submit" class="btn btn-primary" [disabled]="formGuardar.get('promptResChat').invalid || responseGPT.get('promptResChat').invalid" mat-button matStepperNext>Guardar Respuesta</button>
                                                                </div> -->
                                                    <!-- <div>
                                                                    <button class="btn btn-primary" mat-button matStepperNext>Siguente Paso</button>
                                                                </div> -->
                                                    <!-- </div> -->

                                                    <!-- </form>    -->


                                                </div>
                                            </div>

                                        </div>
                                    </cdk-accordion-item>
                                </cdk-accordion>

                            </div>
                        </mat-step>

                        <!-- <mat-step [stepControl]="estudioMerca">
                                <ng-template matStepLabel>Competencia</ng-template>

                            </mat-step> -->
                        <mat-step *ngIf="showPreviousSteps"><!--[stepControl]="fromAvatarNom"-->

                            <ng-template matStepLabel>Avatar</ng-template>
                            <div class="">
                                <div class="bloque-item">
                                    <div style="padding: 0 1rem 1rem 1rem;">
                                        <div class="mb-1">
                                            <span class="bloque-title fontArial">Diseña tu Avatar</span>
                                        </div>
                                        <div class="row">
                                            <h4 class="fontArial">CÓMO SACARLE EL MÁXIMO PROVECHO:</h4>
                                        </div>
                                        <div class="row fontArial">
                                            <p>Esta hoja de trabajo requiere tiempo y esfuerzo, así que a mover las
                                                neuronas 😅. Ten en cuenta que todo lo que escribirás 
                                                será tomando en cuenta <strong>el punto de vista de tu cliente</strong>.
                                                NO EL TUYO. **Ponte en sus zapatos** (literalmente).<br><br>

                                                Ten en cuenta que no puedes atender a todo el mundo. Sin especializarse
                                                nos volvemos generalistas, no podremos diferenciarnos y convertir
                                                nuestro negocio en una experiencia única.<br><br>

                                                Por último, recuerda que este ejercicio toma su tiempo, pero es clave
                                                para ayudarnos a desarrollar una identidad, un mensaje de marketing
                                                poderoso y una oferta diferente a la del resto.<br><br>

                                                <strong>Busca que tu producto tenga un factor WOW, alguna innovación que
                                                    lo haga único y MUY superior a tus competidores o a lo que ya hay en
                                                    el mercado.</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p class="fontArial" style="margin: 0; ">1.- Ahora vamos a las propuestas para el nombre del curso. Escribe las palabras clave…</p>
                            <!-- <mat-form-field class="example-chip-list" style="width: 100%;">
                                        <mat-label>Palabras clave</mat-label>
                                        <mat-chip-list #chipList aria-label="palabras clave">
                                            <mat-chip *ngFor="let nombreCurso of nombresCurso" [selectable]="selectable"
                                                    [removable]="removable" (removed)="remove(nombreCurso)">
                                            {{nombreCurso.name}}
                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                            </mat-chip>
                                            <input placeholder="Palabras clave"
                                                    [matChipInputFor]="chipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    [matChipInputAddOnBlur]="addOnBlur"
                                                    (matChipInputTokenEnd)="add($event)">
                                        </mat-chip-list>
                                    </mat-form-field> -->

                            <form [formGroup]="fromAvatarNom" (ngSubmit)="guardarPromptAvatar()">
                                <div class="row">
                                    <mat-form-field required class="example-chip-list" appearance="outline"
                                        style="margin-top: 1rem;">
                                        <mat-label>Palabras Clave</mat-label>
                                        <mat-chip-grid #chipGrid>
                                            @for (nombreCurso of nombresCurso; track nombreCurso) {
                                            <mat-chip-row (removed)="remove(nombreCurso)" [editable]="true"
                                                [aria-description]="'press enter to edit ' + nombreCurso.name">
                                                {{nombreCurso.name}}
                                                <a matChipRemove [attr.aria-label]="'remove ' + nombreCurso.name">
                                                    <mat-icon>cancel</mat-icon>
                                                </a>
                                            </mat-chip-row>
                                            }
                                            <input placeholder="Palabras clave" [matChipInputFor]="chipGrid"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)"
                                                formControlName="resAvatarNom" />
                                        </mat-chip-grid>

                                    </mat-form-field>
                                </div>
                                <div>
                                    <p class="fontArial mt-3" style="margin: 0; ">Resultados de las propuestas
                                        de nombres para tu curso:</p>
                                </div>
                                <div class="" [innerHTML]="respuestaAvatar">
                                    <!-- <textarea style="width: 100%;">

                                            </textarea> -->

                                    <!-- <textarea [(value)]="textAvatar" style="height: 100%; width: 100%; height: 9rem" ></textarea> -->
                                </div>

                                <div class="form-group">
                                    <div style="justify-content: end; margin-top: 1rem;">
                                        <button *ngIf="!respuestaAvatar"
                                            [disabled]="spinnerAvatar || nombresCurso.length == 0 || actionName || generarNombre"
                                            type="submit" class="btn btn-primary align-items">
                                            {{ !spinnerAvatar ? 'Generar nombres' : 'Generando nombres'}}
                                            <div *ngIf="spinnerAvatar" style="margin-left: 1rem;" class="spinner-border"
                                                role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <hr>

                            <div class="form-group">
                                <div style="display: flex; margin-top: 1rem; justify-content: space-between;">

                                    <button *ngIf="guardarContinuarAvatar && respuestaAvatar"
                                        [disabled]="nombresCurso.length <= 0 " class="btn btn-primary" mat-button
                                        matStepperNext (click)="notifiacacionAvatar()">Guardar y
                                        Continuar</button><!--mat-button matStepperNext-->
                                    <button *ngIf="avatarContinuar" class="btn btn-primary" mat-button
                                        matStepperNext>Continuar</button>
                                </div>
                            </div>
                            <!-- <div class="row-md-6" style="margin-bottom: 1rem;">
                                        <p>2.- Copia esto y pegalo en <a href="https://chatgpt.com/">chatGPT</a></p>
                                        <div style="position: relative;">
                                            <textarea id="promptChatGPTAvatar" class="form-control textArea" [(value)]="promptPalabrasClave"
                                                rows="4" placeholder="Prompt" readonly style="height: 100%;"></textarea>
                                            <button style="padding: 3px; position: absolute; top: 0; right: 0;"
                                                class="btn btn-outline-secondary copy-button" type="button"
                                                (click)="copyToClipboardAvatar()">
                                                <i style="margin: 0;" class="fa fa-copy"></i>
                                            </button>
                                        </div>
                                    </div>


                                    <form [formGroup]="fromAvatarNom" >
                                        <div class="row">
                                            <div class="col-md-4" style="flex: 0 0 100%; max-width: 100%;">
                                                <div class="form-group ">
                                                <span>3.- Pega la respuesta de chatGPT</span>
                                                <ckeditor [editor]="Editor"  [data]="textAvatar" >
                                                </ckeditor>
                                                </div>



                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div style="justify-content: end; margin-bottom: 1rem;">
                                                <button type="submit" class="btn btn-primary" >Guardar Respuesta</button>
                                            </div>
                                        </div>
                                    </form>  -->
                        </mat-step>

                        <mat-step [stepControl]="fourthFormGroup" *ngIf="showPreviousSteps">
                            <ng-template matStepLabel class="fontArial">Estructura</ng-template>
                            <div class="mt-4">
                                <div class="bloque-item">
                                    <span class="bloque-title fontArial">Estructura del curso</span>
                                    <mat-tab-group #tabGroupMerca>
                                        <mat-tab label="Introducir datos">

                                            <div class="col-md-6" style="padding: 1rem;">
                                                <form [formGroup]="fourthFormGroup" (ngSubmit)="esturcturaCurso()">
                                                    <!-- <h4 style="margin-top: 1rem;">¿Cuál es tu profesión?</h4> -->

                                                    <div class="row">
                                                        <mat-form-field appearance="outline" class="mb-3">
                                                            <mat-label>¿Cuál es tu profesión?</mat-label>
                                                            <input matInput type="text" formControlName="QuienEres"
                                                                required aria-describedby="addon-wrapping"
                                                                (input)="updateInputValueAvatar($event)">
                                                        </mat-form-field>
                                                    </div>




                                                    <!-- <h4 style="margin-top: 1rem;">¿Qué tema deseas impartir en tu curso?</h4> -->
                                                    <div class="row">

                                                        <mat-form-field appearance="outline" class="mb-3">
                                                            <mat-label>¿Qué tema deseas impartir en tu
                                                                curso?</mat-label>
                                                            <input matInput type="text"
                                                                formControlName="QueQuieresEnseñar" required
                                                                aria-describedby="addon-wrapping"
                                                                (input)="updateInputValueAvatarEn($event)">
                                                        </mat-form-field>
                                                    </div>


                                                    <!-- <p>1.- Copia esto y pegalo en <a href="https://chatgpt.com/">chatGPT</a></p> -->
                                                    <!-- <button type=submit class="btn btn-primary">Generar Estructura del Curso</button> -->

                                                    <button *ngIf="!continuarEstructura" [disabled]="spinnerEstructura "
                                                        type="submit" class="btn btn-primary align-items"
                                                        [disabled]="fourthFormGroup.invalid">
                                                        {{ !spinnerEstructura ? 'Generar estructura del curso' :
                                                        'Generando estructura' }}
                                                        <div *ngIf="spinnerEstructura" style="margin-left: 1rem;"
                                                            class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </button>

                                                </form>
                                            </div>

                                        </mat-tab>
                                        <mat-tab label="Respuesta">
                                            <!-- <div  [innerHTML]="respuestaEstructura" >



                                                </div>
                                                <ckeditor [editor]="Editor" [data]="respuestaEstructura">

                                                </ckeditor> -->
                                            <div>
                                                <textarea *ngIf="respuestaEstructura != undefined"
                                                    style="height: 260px; width: 100%;"
                                                    [(value)]="respuestaEstructura"></textarea>
                                                <p *ngIf="respuestaEstructura == undefined">**No haz generado ninguna
                                                    respuesta** </p>
                                            </div>
                                            <div>
                                                <p>Copia el texto de la respuesta con respecto a los nombres de los Módulos y lecciones y pégalo en un documento de texto. <br>
                                                   ¡Ojo! Utilizarás este contenido en el siguiente paso. 
                                                </p>  
                                            </div>
                                            <div class="form-group">
                                                <div style="justify-content: end; margin-top: 1rem;">

                                                    <button *ngIf="!continuarEstructura"
                                                        [disabled]="continuarGuardarEstructura" type="submit"
                                                        class="btn btn-primary" mat-button matStepperNext
                                                        (click)="notificacionEstructura()">Guardar y
                                                        Continuar</button><!--mat-button matStepperNext-->
                                                    <button *ngIf="continuarEstructura" class="btn btn-primary"
                                                        mat-button
                                                        matStepperNext>Continuar</button><!--mat-button matStepperNext-->

                                                </div>
                                            </div>



                                        </mat-tab>
                                    </mat-tab-group>


                                    <!-- <form [formGroup]="formProducto" (ngSubmit)="guradarPromptProducto()">
                                            <div class="row">
                                                <div class="col-md-4" style="flex: 0 0 100%; max-width: 100%;">
                                                    <div class="form-group ">
                                                    <textarea id="resPromptProducto" class="form-control" style="margin-top: 2rem;" rows="4" placeholder="Prompt"
                                                        formControlName="resPromptProducto"
                                                        [ngClass]="{'error' :  formProducto.get('resPromptProducto').invalid  && banderaGuardarPrompt }"></textarea>
                                                        <span>2.- Pega la respuesta de chatGPT</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div style="justify-content: end; margin-bottom: 1rem;">
                                                    <button mat-button matStepperNext  [disabled]=" formProducto.get('resPromptProducto').invalid " (click)="notification()" type="submit" class="btn btn-primary" >Guardar Respuesta</button>
                                                </div>
                                            </div>
                                        </form> -->

                                </div>
                            </div>



                        </mat-step>

                        <mat-step label="Checklist">
                            <!-- <div class="accordion">
                                <cdk-accordion class="example-accordion" style="max-width: 100%;">
                                    Acordeón inicial para el primer módulo
                                    <cdk-accordion-item #accordionCheck="cdkAccordionItem"
                                        class="example-accordion-item" role="button" tabindex="0"
                                        id="accordion-header-0" [attr.aria-expanded]="accordionCheck.expanded"
                                        [attr.aria-controls]="'accordion-body-0'">

                                        <div class="example-accordion-item-header" (click)="accordionCheck.toggle()">
                                            <div class="module-title">
                                                <span class="module-title-text">Módulo 1.</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="custom-form-field">
                                                <mat-label>Título del Módulo</mat-label>
                                                <input matInput [(ngModel)]="modulos[0].Name" class="custom-input" />
                                            </mat-form-field>

                                            <span class="example-accordion-item-description">
                                                Click para {{ accordionCheck.expanded ? 'cerrar' : 'abrir' }}
                                            </span>
                                        </div>

                                        <div class="example-accordion-item-body" role="region"
                                            [style.display]="accordionCheck.expanded ? '' : 'none'"
                                            id="accordion-body-0" [attr.aria-labelledby]="'accordion-header-0'">

                                            Campos fijos para lecciones del primer módulo
                                            <div class="">
                                                <mat-form-field appearance="outline" class="custom-form-field">
                                                    <mat-label>Título de la Lección 1</mat-label>
                                                    <input matInput [(ngModel)]="modulos[0].Topics[0].Title"
                                                        placeholder="Título de la Lección 1"
                                                        class="custom-input-content" />
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="custom-form-field">
                                                    <mat-label>Título de la Lección 2</mat-label>
                                                    <input matInput [(ngModel)]="modulos[0].Topics[1].Title"
                                                        placeholder="Título de la Lección 2"
                                                        class="custom-input-content" />
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="custom-form-field">
                                                    <mat-label>Título del Ejercicio Práctico</mat-label>
                                                    <input matInput [(ngModel)]="modulos[0].Topics[2].Title"
                                                        placeholder="Título del Ejercicio Práctico"
                                                        class="custom-input-content" />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </cdk-accordion-item>

                                    Renderizado de módulos adicionales añadidos dinámicamente
                                    <ng-container *ngFor="let modulo of modulos.slice(1); let i = index">
                                        <cdk-accordion-item #accordionCheck="cdkAccordionItem"
                                            class="example-accordion-item" role="button" tabindex="0"
                                            id="accordion-header-{{i + 1}}"
                                            [attr.aria-expanded]="accordionCheck.expanded"
                                            [attr.aria-controls]="'accordion-body-' + (i + 1)">

                                            <div class="example-accordion-item-header"
                                                (click)="accordionCheck.toggle()">
                                                <div class="module-title">
                                                    <span class="module-title-text">Módulo {{i + 2}}.</span>
                                                </div>
                                                <mat-form-field appearance="outline" class="custom-form-field">
                                                    <mat-label>Título del Módulo</mat-label>
                                                    <input matInput [(ngModel)]="modulo.Name"
                                                        placeholder="Título del Módulo" class="custom-input" />
                                                </mat-form-field>

                                                <span class="example-accordion-item-description">
                                                    Click para {{ accordionCheck.expanded ? 'cerrar' : 'abrir' }}
                                                </span>
                                            </div>

                                            <div class="example-accordion-item-body" role="region"
                                                [style.display]="accordionCheck.expanded ? '' : 'none'"
                                                id="accordion-body-{{i + 1}}"
                                                [attr.aria-labelledby]="'accordion-header-' + (i + 1)">

                                               
                                                <div>
                                                    <mat-form-field appearance="outline" class="custom-form-field">
                                                        <mat-label>Título de la Lección 1</mat-label>
                                                        <input matInput [(ngModel)]="modulo.Topics[0].Title"
                                                            placeholder="Título de la Lección 1"
                                                            class="custom-input-content" />
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="custom-form-field">
                                                        <mat-label>Título de la Lección 2</mat-label>
                                                        <input matInput [(ngModel)]="modulo.Topics[1].Title"
                                                            placeholder="Título de la Lección 2"
                                                            class="custom-input-content" />
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="custom-form-field">
                                                        <mat-label>Título del Ejercicio Práctico</mat-label>
                                                        <input matInput [(ngModel)]="modulo.Topics[2].Title"
                                                            placeholder="Título del Ejercicio Práctico"
                                                            class="custom-input-content" />
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </cdk-accordion-item>
                                    </ng-container>
                                </cdk-accordion>

                                Botón para agregar nuevos módulos
                                <div class="form-group">
                                    <div class="button-container">
                                        <button mat-raised-button class="btn btn-primary"
                                            (click)="agregarModulo()">Agregar Módulo</button>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="form-group">
                                <div class="button-container-2">
                                    <button class="btn btn-primary" mat-raised-button matStepperNext
                                        (click)="enviarTodosLosModulos()">Guardar y Continuar</button>
                                </div>
                            </div> -->
                            <cdk-accordion class="example-accordion">
                                <!-- Módulos dinámicos con acordeones -->
                                <ng-container *ngFor="let module of modulos; let i = index">
                                  <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="region"
                                                      tabindex="0" id="accordion-header-{{ i }}" [attr.aria-expanded]="accordionItem.expanded"
                                                      [attr.aria-controls]="'accordion-body-' + i">
                              
                                    <div class="example-accordion-item-header2 fontArial" (click)="accordionItem.toggle()">
                                      <div class="module-title2">
                                        <span class="module-title-text">MÓDULO {{ i }} - {{ modulos[i].Name }}</span>
                                      </div>
                                      <mat-icon style="line-height: 35px;">
                                        {{ accordionItem.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                                      </mat-icon>
                                    </div>
                              
                                    <div class="example-accordion-item-body" role="region"
                                         [style.display]="accordionItem.expanded ? '' : 'none'"
                                         id="accordion-body-{{ i }}" [attr.aria-labelledby]="'accordion-header-' + i">
                              
                                      <!-- Solo mostrar el título de nuevo módulo si i > 0 -->
                                      <div *ngIf="i > 0">
                                        <mat-form-field appearance="outline" class="custom-form-field mb-3 w-100" style="margin-top: 10px;">
                                          <mat-label>Título del Módulo </mat-label>
                                          <input matInput [(ngModel)]="module.Name" placeholder="Título del Módulo" (change)="checkFormValidity()" required/>
                                        </mat-form-field>
                                      </div>
                              
                                      <!-- Contenido del módulo -->
                                      <div class="contenido-modulo">
                                        <div class="lecciones-container row">
                                          <!-- Columna de Inputs -->
                                          <div class="col-12" [ngClass]="{'col-md-6' : expandedLessons.includes(true)}">
                                            <div *ngFor="let topic of module.Topics; let j = index">
                                              <!-- Lección 1 -->
                                              <mat-form-field appearance="outline" class="custom-form-field mb-3 w-100">
                                                <mat-label>Título de la Lección {{ j+1 }} </mat-label>
                                                <div class="input-button-container">
                                                  <input matInput [(ngModel)]="module.Topics[j].Title"
                                                         placeholder="Título de la Lección {{ j+1 }}" 
                                                         (change)="onInputChange(i)" 
                                                         readonly required/>
                                                  <button mat-raised-button class="btn btn-primary btn-contenido" (click)="openModal(module, j)">
                                                    Editar Contenido
                                                  </button>                                                                        
                                                </div>
                                              </mat-form-field>
                                            </div>
                                          </div>
                                        </div>
                              
                                        <!-- Botones para agregar y quitar lección -->
                                        <div class="row" *ngIf="i > 0">
                                            <div class="col d-flex justify-content-between mb-1">
                                                <button class="btn btn-primary" (click)="addTopic(i)">
                                                    Agregar lección
                                                </button>
                                                <button class="btn btn-secondary" (click)="removeTopic(i)" [disabled]="module.Topics.length <= 1">
                                                    Quitar lección
                                              </button>
                                            </div>
                                        </div>                                          
                                      </div>
                                    </div>
                                  </cdk-accordion-item>
                                </ng-container>
                            </cdk-accordion>
                                                        
                        
                            <!-- Botón para agregar nuevos módulos -->
                            <div class="form-group">
                                <div class="button-container">
                                    <button mat-raised-button class="btn btn-primary" (click)="agregarModulo()">Agregar Módulo</button>
                                </div>
                            </div>
                        
                            <!-- Botón de Guardar y Continuar -->
                            <div class="form-group">
                                <div class="button-container-2">
                                    <button *ngIf="!isContinueButtonVisible" mat-button matStepperNext class="btn btn-primary" (click)="enviarTodosLosModulos()" [disabled]="!isFormValid">
                                        Guardar y Continuar
                                    </button>
                                    <button *ngIf="isContinueButtonVisible" mat-button class="btn btn-primary" matStepperNext>Continuar</button>
                                </div>
                            </div>

                        </mat-step>

                        <mat-step label="Contenido">
                            <!-- <div class="mt-4" >
                                    <form [formGroup]="courseForm" (ngSubmit)="onSubmit()" class="needs-validation row">
                                        <div class="bloque-item">
                                            <span class="bloque-title">Datos generales</span>
                                            <div class="row"> -->
                            <!-- <div class="col-12 col-md-8">
                                                    <div class="row"> -->
                            <!-- <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label for="title">Título</label>
                                                                <input type="text" class="form-control" (keyup)="generateSlug($event)"
                                                                    [ngClass]="{'error' : submitted && courseForm.controls.Title.hasError('required') }"
                                                                    formControlName="Title" id="title" placeholder="Titulo">
                                                            </div>
                                                        </div> -->


                            <!-- <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label>Categoría:</label>
                                                                <select formControlName="category_id" name="category" class="custom-select form-select"
                                                                    required="">
                                                                    <option value="" selected disabled>Selecciona</option>
                                                                    <option *ngFor="let cat of categories" [value]="cat.id">{{
                                                                        cat.ValueAlpha }}</option>
                                                                </select>
                                                            </div>
                                                        </div> -->

                            <!-- <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label for="title">Precio</label>
                                                                <input type="text" class="form-control" formControlName="UnitPrice" id="title"
                                                                    [ngClass]="{'error' : submitted && courseForm.controls.UnitPrice.hasError('required') }"
                                                                    placeholder="Precio">
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label>Nivel: </label>
                                                                <select [formControlName]="'level_id'" name="level" class="custom-select form-select" required="">
                                                                    <option value="" selected disabled>Selecciona</option>
                                                                    <option *ngFor="let lvl of levelList" [value]="lvl.id">{{
                                                                        lvl.ValueAlpha }}</option>
                                                                </select>
                                                            </div>
                                                        </div> -->


                            <!-- </div>
                                                </div> -->
                            <!-- <div class="col-12 col-md-4">
                                                    <label for="title">Agregar imagen del curso</label>
                                                    <ngx-dropzone (change)="onSelectImages($event)" [multiple]="false">
                                                        <ngx-dropzone-label class="label-file"> Arrastra una imagen aquí o haz clic
                                                            para seleccionar una
                                                            imagen desde tu computadora.</ngx-dropzone-label>
                                                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of imagesX" [file]="f"
                                                            [removable]="true">
                                                            <ngx-dropzone-label>{{ f.name.substr(1,10) }}... / ({{ f.type
                                                                }})</ngx-dropzone-label>
                                                        </ngx-dropzone-image-preview>
                                                    </ngx-dropzone>
                                                    <section *ngIf="responseImgFile" style="margin-top: 10px;">
                                                        <a [href]="responseImgFile" target="_black" style="color:#33bd38">imagen
                                                            cargada <i class="fa fa-check" aria-hidden="true"></i></a>
                                                    </section>
                                                </div> -->
                            <!-- </div>
                                        </div>

                                        <div class="bloque-item">
                                            <span class="bloque-title">Información del curso</span>
                                            <div class="row"> -->
                            <!-- <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label>Nivel: </label>
                                                        <select [formControlName]="'level_id'" name="level" class="custom-select form-select" required="">
                                                            <option value="" selected disabled>Selecciona</option>
                                                            <option *ngFor="let lvl of levelList" [value]="lvl.id">{{
                                                                lvl.ValueAlpha }}</option>
                                                        </select>
                                                    </div>
                                                </div> -->
                            <!-- <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="title">Recursos descargables</label>
                                                        <input type="text" class="form-control" formControlName="TotalHours" id="title"
                                                            [ngClass]="{'error' : submitted && courseForm.controls.TotalHours.hasError('required') }"
                                                            placeholder="Recursos descargables">
                                                    </div>
                                                </div> -->

                            <!-- <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="description">Descripción</label>
                                                        <textarea class="form-control" formControlName="Description" id="description" rows="3"
                                                            placeholder="Ingresa una descripción del curso"></textarea>
                                                    </div>
                                                </div> -->
                            <!-- <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="description">Lecturas</label>
                                                        <textarea class="form-control" formControlName="Introduction" id="description" rows="3"
                                                            placeholder="Ingresa las Lecutras del curso"></textarea>
                                                    </div>
                                                </div> -->
                            <!-- </div>


                                            <div class="row">
                                                <div class="col-md-6">

                                                    <div class="form-group">
                                                        <label for="description">Qué Aprenderas</label>
                                                        <textarea class="form-control" formControlName="Objective" id="description" rows="3"
                                                            placeholder="Ingresa lo que se aprendera en el curso"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">

                                                    <div class="form-group">
                                                        <label for="description">Dirigido a</label>
                                                        <textarea class="form-control" formControlName="TargetMarket" id="description" rows="3"
                                                            placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div> -->
                            <!-- <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="description">Certificados</label>
                                                        <textarea class="form-control" formControlName="certificados" id="description" rows="3"
                                                            placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div> -->
                            <!-- </div>

                                        <div class="bloque-item">
                                            <span class="bloque-title">Plan de Estudio</span>
                                            <div class="row">
                                                <div class="col-12 col-md-8">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0">
                                                                    Título</label>
                                                                <input class="form-control" id="validationCustom05" type="text"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.Name" name="name">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0"> Número de
                                                                    temas</label>
                                                                <input class="form-control" id="validationCustom05" type="number"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.numTopics" name="topics">
                                                            </div>
                                                        </div> -->
                            <!-- <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0">
                                                                    Porcentaje</label>
                                                                <input class="form-control" id="validationCustom05" type="number"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.Percentage" name="percent">
                                                            </div>
                                                        </div> -->
                            <!-- <div class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label for="validationCustom05" class="col-form-label pt-0">
                                                                    Duración</label>
                                                                <input class="form-control" id="validationCustom05" type="text"
                                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="module.courseDuration" name="duration">
                                                            </div>
                                                        </div> -->
                            <!-- </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <label>Cargar video</label>
                                                    <ngx-dropzone (change)="onSelectVideo($event)" [multiple]="false">
                                                        <ngx-dropzone-label *ngIf="!viewLoaderVideo && module.VideoUrl === ''" class="label-file"> Arrastra aquí
                                                            el video del
                                                            módulo.</ngx-dropzone-label>
                                                        <div *ngIf="viewLoaderVideo" class="spinner-border text-danger" role="status">
                                                            <span class="sr-only">Cargando...</span>
                                                        </div>
                                                        <ngx-dropzone-label *ngIf="!viewLoaderVideo && module.VideoUrl !== ''" class="label-file">
                                                            <i class="fa fa-check text-success"></i>
                                                            Video cargado correctamente.
                                                        </ngx-dropzone-label>
                                                    </ngx-dropzone>
                                                </div>
                                                <div class="col-12 d-flex justify-content-end py-2">
                                                    <button [disabled]="module.VideoUrl === ''" class="btn btn-primary" (click)="addModule();">Agregar
                                                        módulo</button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <table *ngIf="modules.length !== 0" class="w-100">
                                                        <tr>
                                                            <th>Eliminar</th>
                                                            <th>Título</th>
                                                            <th>Número de temas</th>
                                                            <th>Porcentaje</th>
                                                            <th>Duración</th>
                                                        </tr>
                                                        <tr *ngFor="let module of modules">
                                                            <td>
                                                                <button class="btn btn-sm btn-primary"><i class="fa fa-trash mr-0 text-white"></i></button>
                                                            </td>
                                                            <td><b>{{ module.Name }}</b></td>
                                                            <td>{{ module.numTopics }}</td>
                                                            <td>{{ module.Percentage }}</td>
                                                            <td>{{ module.courseDuration }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </form> -->
                            <!-- </div>
                                <button type="submit" class="btn btn-primary" >Crear curso</button>
                                <div class="mt-4">
                                    <div class="bloque-item" style="height: 33rem">
                                        <span class="bloque-title">Toma el curso OBS</span>

                                        <iframe
                                        [src]="iframeSrc"
                                        width="100%"
                                        height="100%"
                                        title="Iframe Example"
                                        frameborder="0"
                                        allowfullscreen
                                        sandbox="allow-scripts allow-same-origin"
                                        >
                                        </iframe>
                                    </div>
                                </div> -->
                            <app-new-product [productId]="productId"
                                (notifyParent)="getNotification($event)"></app-new-product>
                        </mat-step>

                        <mat-step label="Recursos">
                            <!-- <ng-template matStepLabel>Recursos</ng-template> -->
                            <!-- <div class="mt-4">
                                <div class="bloque-item" style="height: 33rem">
                                    <span class="bloque-title">Toma el curso OBS</span>

                                    <iframe [src]="iframeSrc" width="100%" height="100%" title="Iframe Example"
                                        frameborder="0" allowfullscreen sandbox="allow-scripts allow-same-origin">
                                    </iframe>
                                </div>
                            </div> -->
                            <p class="fontArial" style="margin-bottom: 0px; margin-top: 10px; font-size: 16px;">
                                A continuación cargarás los videos de tu curso.
                                (Si aún no tienes los videos de tu curso, te invito al <a href="https://www.learnify.com.mx/contenidoCurso/f625cb96-9ac4-49cd-9898-5aca791ad049" target="_blank">curso de OBS</a>)
                            </p>
                            <cdk-accordion>
                                <!-- Filtramos los módulos sin excluir el módulo 0 -->
                                <ng-container *ngFor="let modulo of modulos; let i = index">
                                    <cdk-accordion-item [expanded]="i === 1" class="example-accordion-item">
                                        <div class="example-accordion-item-header2 fontArial" (click)="togglePanel(i)" style="display: flex; justify-content: space-between;">
                                            <span class="module-title-text">MÓDULO {{ i }} - {{ modulo.Name || 'Módulo ' + (i + 1) }}</span>
                                            <mat-icon style="line-height: 35px;">
                                              {{ expandedIndex === i ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                                            </mat-icon>
                                        </div>

                                        <div class="example-accordion-item-body" *ngIf="modulo.Topics && modulo.Topics.length && expandedIndex === i">
                                            <ul>
                                                <li *ngFor="let lesson of modulo.Topics; let j = index">
                                                    <!-- Título de la lección con campo editable -->
                                                    <mat-form-field appearance="outline" class="custom-form-field mb-3 w-100">
                                                        <mat-label>Título de la Lección {{ j+1 }}</mat-label>
                                                        <div class="input-button-container">
                                                            <input matInput [(ngModel)]="lesson.Title"
                                                                placeholder="Título de la Lección {{ j+1 }}" 
                                                                (change)="onInputChange(i)" 
                                                                readonly required />
                                                            <button mat-raised-button class="btn btn-primary btn-contenido" 
                                                                (click)="onSelectVideo(i, j)" 
                                                                [disabled]="lesson.uploaded || lesson.uploading">
                                                                {{ lesson.uploading ? 'Cargando video...' : lesson.uploaded ? 'Video cargado' : 'Cargar video' }}
                                                            </button>
                                                        </div>
                                        
                                                    </mat-form-field>

                                                    <!-- Barra de progreso debajo del input -->
                                                    <div *ngIf="lesson.uploading" style="width: 100%; margin-top: 10px;">
                                                        <ngb-progressbar [value]="lesson.progress" 
                                                                        [striped]="true" 
                                                                        [animated]="true" 
                                                                        [type]="getProgressBarType(lesson.progress)" 
                                                                        [showValue]="true" 
                                                                        class="custom-progress">
                                                        </ngb-progressbar>
                                                        <!-- Mensaje de video subido -->
                                                        <div class="text-center" *ngIf="lesson.progress === 100" style="margin-top: 5px; font-weight: bold; color: green;">
                                                            Video Subido
                                                        </div>
                                                    </div>
                                        
                                                    <!-- Información del video -->
                                                    <div class="mt-3" *ngIf="lesson.uploaded">
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center" style="width: 60%;">Nombre</th>
                                                                    <th class="text-center" style="width: 10%;">Formato</th>
                                                                    <th class="text-center" style="width: 10%;">Tamaño</th>
                                                                    <th class="text-center" style="width: 20%;">Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <!-- Nombre del video -->
                                                                    <td>{{ lesson.FileName }}</td>
                                        
                                                                    <!-- Tipo de archivo -->
                                                                    <td>{{ lesson.Type || 'Video' }}</td>
                                        
                                                                    <!-- Peso del archivo -->
                                                                    <td>{{ lesson.FileSize || 'Desconocido' }}</td>
                                        
                                                                    <!-- Acciones para gestionar el video -->
                                                                    <td>
                                                                        <div ngbDropdown container="body">
                                                                            <button class="btn btn-primary mb-2" ngbDropdownToggle>Acciones</button>
                                                                            <div ngbDropdownMenu>
                                                                                <!-- Ver video -->
                                                                                <a href="{{ lesson.videoUrl }}" target="_blank" ngbDropdownItem>
                                                                                    <i class="fa fa-eye"></i> Ver video
                                                                                </a>
                                                                        
                                                                                <!-- Reemplazar video -->
                                                                                <button ngbDropdownItem [disabled]="lesson.uploading" (click)="onSelectVideo(i, j)" style="line-height: inherit;">
                                                                                    <i class="fa fa-upload"></i> Reemplazar video
                                                                                    <!-- Spinner de carga -->
                                                                                    <div *ngIf="lesson.uploading" style="margin-left: 1rem;" class="spinner-border" role="status">
                                                                                        <span class="sr-only">Loading...</span>
                                                                                    </div>
                                                                                </button>
                                                                        
                                                                                <!-- Borrar video -->
                                                                                <button ngbDropdownItem (click)="onDeleteVideo(i, j)" style="line-height: inherit;">
                                                                                    <i class="fa fa-trash"></i> Borrar video
                                                                                </button>
                                                                            </div>
                                                                        </div>                                                                        
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <hr>
                                                </li>
                                            </ul>
                                        </div>                                        
                                          
                                    </cdk-accordion-item>
                                </ng-container>
                            </cdk-accordion>
                            <!-- File Input Element for Video Upload -->
                            <input type="file" #fileInput accept="video/*" style="display:none"
                                (change)="onFileChange($event)">
                            <!-- <div class="row">
                                <label for="title">Banner del curso</label>
                                <div class="col-12 col-md-4">
                                    <img *ngIf="responseImgFile !== ''" class="w-100" [src]="responseImgFile" alt="">
                                    <img *ngIf="responseImgFile === ''" class="w-100"
                                        src="https://tse2.mm.bing.net/th/id/OIG3.Xz0M5tRen4dQ6MqpQbHZ?pid=ImgGn" alt="">
                                </div>
                                <div class="col-12 col-md-4">
                                    <ngx-dropzone (change)="onSelectImages($event)" [multiple]="false">
                                        <ngx-dropzone-label class="label-file"> Arrastra una imagen aquí o haz clic
                                            para seleccionar una
                                            imagen desde tu computadora.</ngx-dropzone-label>
                                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                            *ngFor="let f of imagesX" [file]="f" [removable]="true">
                                            <ngx-dropzone-label>{{ f.name.substr(1,10) }}... / ({{ f.type
                                                }})</ngx-dropzone-label>
                                        </ngx-dropzone-image-preview>
                                    </ngx-dropzone>
                                    <section *ngIf="responseImgFile" style="margin-top: 10px;">
                                        <a [href]="responseImgFile" target="_black" style="color:#33bd38">imagen
                                            cargada <i class="fa fa-check" aria-hidden="true"></i></a>
                                    </section>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="button-container-2">
                                    <button class="btn btn-primary" mat-raised-button (click)="getSlug()">Guardar y
                                        Continuar</button>
                                </div>
                            </div> -->
                            <div class="form-group">
                                <div class="button-container-2">
                                    <button mat-button class="btn btn-primary" matStepperNext>Continuar</button>
                                </div>
                            </div>
                        </mat-step>

                        <mat-step [stepControl]="secondFormGroup">
                            <form [formGroup]="secondFormGroup">
                                <ng-template matStepLabel>Ficha</ng-template>
                                <iframe [src]="WEBURL+'/curso/'+slug | safeUrl" class="w-100"
                                    style="height: 600px;" frameborder="0"></iframe>
                            </form>
                            <div class="form-group">
                                <div class="button-container-2">
                                    <button class="btn btn-primary" mat-raised-button
                                        (click)="setActiveCourse()">Mandar a Revisión</button>
                                </div>
                            </div>
                        </mat-step>
                        <!-- <mat-step [stepControl]="secondFormGroup">
                                <form [formGroup]="secondFormGroup">
                                  <ng-template matStepLabel>Resumen</ng-template>
                                  <mat-form-field>
                                    <mat-label>Address</mat-label>
                                    <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY"
                                           required>
                                  </mat-form-field>
                                  <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                  </div>
                                </form>
                            </mat-step> -->
                    </mat-stepper>





                </div>
            </div>

        </div>
    </div>
</div>

<!-- Modal Template -->
<ng-template #modalTemplate>
    <!-- Título de la lección seleccionada -->
    <h2 class="titulo2">Lección {{ topicSelected + 1 }}: "{{ moduleSelected.Topics[topicSelected]?.Title }}"</h2>
    <button mat-icon-button class="close-button" (click)="cancelComment()">
        <mat-icon>close</mat-icon>
    </button>

    <div class="modal-modulo">
        <!-- Mostrar solo la lección seleccionada -->
        <div class="leccion-seleccionada">
            <!-- Input para el título de la lección seleccionada -->
            <mat-form-field appearance="outline" class="custom-form-field mb-3 w-100">
                <mat-label>Título de la Lección {{ topicSelected + 1 }}</mat-label>
                <input matInput [(ngModel)]="moduleSelected.Topics[topicSelected].Title"
                       placeholder="Título de la Lección {{ topicSelected + 1 }}"
                       (change)="onInputChange(currentModuloIndex)" required/>
            </mat-form-field>

            <div *ngIf="topicSelected !== undefined" class="columna-derecha mb-3">
                <div *ngIf="currentModuloIndex !== 0">
                    <p class="fontArial" style="margin-bottom: 0px; font-size: 15px;">
                        Redacta el contenido que leerá el estudiante al pie de esta lección.(Objetivo de la lección con una breve descripción)
                    </p>
                </div>    
                <div *ngIf="currentModuloIndex === 0" style="margin-bottom: 10px;">
                    <p class="fontArial" style="margin-bottom: 1px; font-size: 15px; margin-top: 10px;">
                        {{ textByLesson[topicSelected + 1] || 'Redacta el contenido que leerá el estudiante al pie de esta lección. (Mensaje por defecto)' }}
                    </p>                      
                    <div class="scrollable-content" [innerHTML]="messagesByLesson[topicSelected + 1]"></div>
                </div>                
                <ckeditor [editor]="Editor"
                          [(ngModel)]="moduleSelected.Topics[topicSelected].Content"
                          [config]="editorConfig"
                          (change)="onCkeditorChange($event, topicSelected)" 
                          required>
                </ckeditor>
                <!-- Este dropzone solo se mostrará si currentModuloIndex no es 0 -->
                
                <div *ngIf="currentModuloIndex !== 0">
                    <p class="fontArial" style="margin-bottom: 1px; font-size: 15px; margin-top: 10px;">
                        Agrega los materiales de estudio:
                    </p>
                    
                    <ngx-dropzone *ngIf="currentModuloIndex !== 0" 
                                  (change)="onResourceSelect($event, currentModuloIndex, topicSelected)" 
                                  accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx">
                        <ngx-dropzone-label>
                            <div class="dz-message needsclick">
                                <i class="fa fa-cloud-upload"></i>
                                <h4>Archivos de trabajo en PDF, Excel o Power Point</h4>
                            </div>
                        </ngx-dropzone-label>
                    </ngx-dropzone>
                
                    <!-- Mostrar error si no hay archivos subidos -->
                    <div *ngIf="!hasFiles(currentModuloIndex, topicSelected)" class="error-message" style="color: red;">
                        Debes subir al menos un archivo.
                    </div>
                </div>      
            </div>            

            <!-- Lista de archivos adjuntos para la lección seleccionada -->
            <div *ngIf="moduleSelected.Topics[topicSelected].Files.length > 0" class="file-list-container">
                <h3 class="file-list-title">Materiales de la lección</h3>
                
                <!-- Encabezados para las columnas -->
                <div class="file-list-header">
                    <span class="file-header">Nombre</span>
                    <span class="file-header">Formato</span>
                    <span class="file-header">Tamaño</span>
                    <span class="file-header">Acciones</span>
                </div>

                <!-- Lista de archivos adjuntos -->
                <!-- Lista de archivos adjuntos -->
                <div *ngFor="let file of moduleSelected.Topics[topicSelected].Files; let i = index" class="file-list-row">
                    <!-- Nombre del archivo -->
                    <span class="file-column">{{ file.name }}</span>

                    <!-- Formato del archivo (extensión) -->
                    <span class="file-column">{{ getFileExtension(file.name) }}</span>

                    <!-- Tamaño del archivo -->
                    <span class="file-column">{{ formatFileSize(file.size) }}</span>

                    <!-- Botón para eliminar el archivo -->
                    <span class="file-column file-actions">
                        <button mat-icon-button (click)="removeFile(currentModuloIndex, topicSelected, i)" class="btn btn-secondary">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                </div>
            </div>    
        </div>

        <!-- Botón para guardar -->
        <div class="modal-footer">
            <button mat-button class="btn btn-primary" (click)="cancelComment()">Guardar Contenido</button>
        </div>
    </div>
</ng-template>

<!-- Modal Template -->
<!-- <ng-template #modalTemplate let-dialogRef="dialogRef">
    <div>
        Título dinámico basado en la lección seleccionada
        <h2>Lección {{ topicSelected + 1 }}</h2>

        Botón para cerrar el modal
        <button mat-icon-button (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </button>

        Contenido del Modal
        <div class="modal-modulo">
            Input para el título de la lección
            <mat-form-field appearance="outline" class="custom-form-field">
                <mat-label>Título de la Lección</mat-label>
                <input matInput [(ngModel)]="moduleSelected.Topics[topicSelected].Title"
                       placeholder="Título de la Lección"/>
            </mat-form-field>

            CKEditor para editar el contenido de la lección
            <ckeditor [editor]="Editor"
                      [(ngModel)]="moduleSelected.Topics[topicSelected].Content"
                      [config]="editorConfig">
            </ckeditor>

            Dropzone para subir archivos adjuntos
            <ngx-dropzone (change)="onResourceSelect($event, currentModuloIndex, topicSelected)" 
                          accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx">
                <ngx-dropzone-label>
                    <h4>Subir archivos</h4>
                </ngx-dropzone-label>
            </ngx-dropzone>

            Lista de archivos adjuntos
            <div *ngIf="moduleSelected.Topics[topicSelected].Files.length > 0">
                <h3>Archivos Adjuntos</h3>
                <mat-list>
                    <mat-list-item *ngFor="let file of moduleSelected.Topics[topicSelected].Files; let i = index">
                        <span>{{ file.name }}</span>
                        <button mat-icon-button (click)="removeFile(currentModuloIndex, topicSelected, i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>

        Botón para guardar los cambios
        <div class="modal-footer">
            <button mat-button color="primary" (click)="dialogRef.close()">Guardar Contenido</button>
        </div>
    </div>
</ng-template> -->


<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-12 pl-0">
                                                    <h4>Cargar imagen de portada</h4>
                                                    <img *ngIf="ImgFileUrl" [src]="[ImgFileUrl]" class="img-fluid blur-up lazyload bg-img mb-3" alt="" style="width: 200px;">
                                                    <div *ngIf="previewImg" class="box-input-file">
                                                        <img class="imgUpload" alt="" [src]="previewImg.base">
                                                    </div>
                                                    <div *ngIf="viewLoaderImg" class="spinner-border text-danger" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <input type="file" name="picture1" class="file-input" accept=".png, .jpg, .jpeg" (change)="onImgSelected($event)" #fileImgUpload>
                                                    <div class="file-upload mt-3">
                                                        <button class="btn btn-primary" (click)="fileImgUpload.click()">
                                                            {{ImgFileUrl ? 'Reemplazar imagen' : "Cargar imagen"}}
                                                            <i class="ml-3 fa fa-cloud-upload" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>


                                                <div class="col-12 mt-5 pl-0">
                                                    <h4>Cargar manual del producto</h4>
                                                    <div *ngIf="previewFile" class="box-input-file py-3">
                                                        <a href="{{previewFile}}" target="_blank" class=""  ><h4>Ver archivo</h4></a>
                                                    </div>
                                                    <div *ngIf="viewLoaderFile" class="spinner-border text-danger" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <input type="file" class="file-input" accept=".pdf" (change)="onFileSelected($event)" #fileUpload>
                                                    <div class="file-upload">
                                                        <button class="btn btn-primary" (click)="fileUpload.click()">
                                                            {{previewFile ? 'Reemplazar archivo' : "Cargar archivo"}}
                                                            <i class="fa fa-cloud-upload ml-3" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <form class="needs-validation" [formGroup]="productForm" (ngSubmit)="onSubmit()" novalidate>
                                <div class="add-product-form">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom01" class="col-form-label pt-0">Nombre</label>
                                                <input class="form-control" id="validationCustom01" type="text" [formControl]="productForm.controls['Title']"
                                                [ngClass]="{'error': submitted && formState?.Title?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom02" class="col-form-label pt-0">SKU</label>
                                                <input class="form-control" id="validationCustom02" type="text" [formControl]="productForm.controls['SKU']"
                                                [ngClass]="{'error': submitted && formState?.SKU?.errors?.required}">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom03" class="col-form-label pt-0">{{ ruta == 'add-course' ? 'Modelo del curso' : 'Modelo de capacitación'}}</label>
                                                <input class="form-control" id="validationCustom03" type="text" [formControl]="productForm.controls['CourseModel']"
                                                [ngClass]="{'error': submitted && formState?.CourseModel?.errors?.required}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom10" class="col-form-label pt-0">Total de horas</label>
                                                <input class="form-control" id="validationCustom10" type="number" min="0" oninput="validity.valid||(value='');"
                                                [formControl]="productForm.controls['TotalHours']"
                                                [ngClass]="{'error': submitted && formState?.TotalHours?.errors?.required || formState?.TotalHours?.errors?.min}">
                                                <span *ngIf="formState?.TotalHours?.errors?.min" class="text-danger">
                                                    No se permiten valores negativos
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom11" class="col-form-label pt-0">Lenguaje</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['Language']"
                                                [ngClass]="{'error': submitted && formState?.Language?.errors?.required}">
                                                    <option value="">Selecciona lenguaje</option>
                                                    <option value="Español">Español</option>
                                                    <option value="Inglés">Inglés</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom12" class="col-form-label pt-0">Lenguaje de subtítulos</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['LanguageSubtitles']"
                                                [ngClass]="{'error': submitted && formState?.LanguageSubtitles?.errors?.required}">
                                                    <option value="">Selecciona subtítulos</option>
                                                    <option value="Español">Español</option>
                                                    <option value="Inglés">Inglés</option>
                                                    <option value="N/A">N/A</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom07" class="col-form-label pt-0">Categoría</label>
                                                <ng-select name="Category" id="Category" [formControl]="productForm.controls['Category']" [ngClass]="{'error': submitted && formState?.Category?.errors?.required}">
                                                    <ng-option *ngFor="let category of categories" [value]="category.ValueAlpha">
                                                        {{category.ValueAlpha}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom08" class="col-form-label pt-0">Sub categoría</label>
                                                <ng-multiselect-dropdown
                                                    [placeholder]="'Seleccionar subcategorías'"
                                                    [settings]="dropdownSettings"
                                                    [formControl]="productForm.controls['SubCategory']"
                                                    [data]="subcategoryList"
                                                    (onSelect)="selectSubCategory($event)"
                                                    (onDeSelect)="deselectSubCategory($event)">
                                                    >
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <div class="form-group">
                                                <label for="validationCustom09" class="col-form-label pt-0">Nivel</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['Level']"
                                                [ngClass]="{'error': submitted && formState?.Level?.errors?.required}">
                                                    <option value="">Selecciona nivel</option>
                                                    <option value="Principiante">Principiante</option>
                                                    <option value="Intermedio">Intermedio</option>
                                                    <option value="Avanzado">Avanzado</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-3">
                                            <div class="form-group">
                                                <label for="validationCustom04" class="col-form-label pt-0">Precio</label>
                                                <input class="form-control" id="validationCustom04" type="number" [formControl]="productForm.controls['UnitPrice']" min="0" oninput="validity.valid||(value='');"
                                                [ngClass]="{'error': submitted && formState?.UnitPrice?.errors?.required || formState?.UnitPrice?.errors?.min}">
                                                <span *ngIf="formState?.UnitPrice?.errors?.min" class="text-danger">
                                                    No se permiten valores negativos
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-3">
                                            <div class="form-group">
                                                <label for="validationCustom17" class="col-form-label pt-0">Precio descuento</label>
                                                <input class="form-control" id="validationCustom17" type="number" min="0" [formControl]="productForm.controls['DiscountPrice']" oninput="validity.valid||(value='');">
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-3">
                                            <div class="form-group">
                                                <label for="validationCustom05" class="col-form-label pt-0">Moneda</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['CurrencyCode']"
                                                [ngClass]="{'error': submitted && formState?.CurrencyCode?.errors?.required}">
                                                    <option value="">Selecciona moneda</option>
                                                    <option value="MXN">MXN</option>
                                                    <option value="USD">USD</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-3">
                                            <div class="form-group">
                                                <label for="validationCustom06" class="col-form-label pt-0">Tipo de acceso</label>
                                                <select class="form-control custom-select" [formControl]="productForm.controls['AccessType']"
                                                [ngClass]="{'error': submitted && formState?.AccessType?.errors?.required}">
                                                    <option value="">Selecciona acceso</option>
                                                    <option value="Online">Online</option>
                                                    <option value="Presencial">Presencial</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom13" class="col-form-label pt-0">Descripción</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['Description']" name="Description" id="validationCustom13" cols="40%" rows="5"
                                                [ngClass]="{'error': submitted && formState?.Description?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom14" class="col-form-label pt-0">Introducción</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['Introduction']" name="Introduction" id="validationCustom14" cols="40%" rows="5"
                                                [ngClass]="{'error': submitted && formState?.Introduction?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom15" class="col-form-label pt-0">Objetivo</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['Objective']" name="Objective" id="validationCustom15" cols="40%" rows="5"
                                                [ngClass]="{'error': submitted && formState?.Objective?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="validationCustom16" class="col-form-label pt-0">Dirigido a</label>
                                                <br>
                                                <textarea class="form-control" [formControl]="productForm.controls['TargetMarket']" name="TargetMarket" id="validationCustom16" cols="40%" rows="5"
                                                [ngClass]="{'error': submitted && formState?.TargetMarket?.errors?.required}"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                <div class="form-group col-12 mb-0 pr-0">
                                    <div class="product-buttons button-save text-right pr-0">
                                        <button class="btn btn-primary"
                                            [disabled]="isLoading" *ngIf="onEdit == false">
                                            {{ isLoading ? 'Creando, espera' : 'Guardar'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>

                                    <div class="product-buttons button-save text-right pr-0">
                                        <button type="submit" class="btn btn-primary" *ngIf="onEdit == true">
                                            {{ isLoading ? 'Editando, espera' : 'Editar'}}
                                            <i class="fa fa-spinner fa-spin"
                                            [hidden]="!isLoading"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  -->