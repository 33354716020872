<div class="container-fluid">
    <div class="row">
        <div class="col-12 py-4 bg-white" [ngClass]="{'col-md-3': step === 0}">
            <form>
                <div class="form-group" *ngIf="step === 0">
                    <mat-form-field class="w-100 mb-3" appearance="outline">
                        <mat-label>Nombre del curso</mat-label>
                        <input matInput type="text" required class="w-100" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="course.Title" (keyup)="generateSlug($event); checkButtonState()" aria-describedby="addon-wrapping">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100 mb-3">
                        <mat-label>Categoría</mat-label>
                        <select matNativeControl [(ngModel)]="course.Category" [ngModelOptions]="{standalone: true}"
                            (change)="selectCategory($event)">
                            <option value="">Selecciona</option>
                            <option *ngFor="let category of categories" [value]="category.id">{{category.ValueAlpha}}
                            </option>
                        </select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100 mb-3">
                        <mat-label>Nivel</mat-label>
                        <select matNativeControl [(ngModel)]="course.Level" [ngModelOptions]="{standalone: true}"
                            (change)="selectLevel($event)">
                            <option value="">Selecciona</option>
                            <option *ngFor="let level of levels" [value]="level.id">{{level.ValueAlpha}}
                            </option>
                        </select>
                    </mat-form-field>
                    <mat-form-field class="w-100 mb-3" appearance="outline">
                        <mat-label>Precio</mat-label>
                        <input matInput type="number" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="course.UnitPrice" (change)="checkButtonState()" aria-describedby="addon-wrapping">
                    </mat-form-field>
                    <mat-form-field class="w-100 mb-3" appearance="outline">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput [(ngModel)]="course.Description" [ngModelOptions]="{standalone: true}"
                                  (keyup)="checkButtonState()" maxlength="400" aria-describedby="addon-wrapping">
                        </textarea>
                        <mat-hint align="end">{{ course.Description?.length || 0 }}/400</mat-hint>
                    </mat-form-field>                    
                    <mat-form-field required class="w-100 mb-3" appearance="outline">
                        <mat-label>¿Qué aprenderás?</mat-label>
                        <mat-chip-grid #chipGrid>
                            @for (learn of learning; track learn) {
                            <mat-chip-row (removed)="remove(learn)" [editable]="true"
                                [aria-description]="'press enter to edit ' + learn.name">
                                {{learn.name}}
                                <button matChipRemove [attr.aria-label]="'remove ' + learn.name">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                            }
                            <input placeholder="¿Qué aprenderás?" [matChipInputFor]="chipGrid"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="add($event)" />
                        </mat-chip-grid>

                    </mat-form-field>

                    <mat-form-field required class="w-100 mb-3" appearance="outline">
                        <mat-label>¿A quién va dirigido?</mat-label>
                        <mat-chip-grid #chipGrid2>
                            @for (learn of target; track learn) {
                            <mat-chip-row (removed)="removeTarget(learn)" [editable]="true"
                                [aria-description]="'press enter to edit ' + learn.name">
                                {{learn.name}}
                                <button matChipRemove [attr.aria-label]="'remove ' + learn.name">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                            }
                            <input placeholder="¿A quién va dirigido?" [matChipInputFor]="chipGrid2"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                                (matChipInputTokenEnd)="addTarget($event)" />
                        </mat-chip-grid>

                    </mat-form-field>

                    <div class="col-12">
                        <label>Imagen profesional</label>
                      
                        <!-- Si hay una imagen guardada y no se ha subido una nueva, mostrar la imagen existente -->
                        <div *ngIf="course.ImgFileUrl && !newImageSelected" class="existing-image-preview">
                          <img [src]="course.ImgFileUrl" alt="Imagen guardada" style="max-width: 100%; height: auto;"/>
                          <button mat-button class="btn btn-sm btn-primary" style="margin-top: 10px;" (click)="removeExistingImage()">Cambiar imagen</button>
                        </div>
                      
                        <!-- Mostrar el dropzone solo si no hay imagen guardada o si se seleccionó una nueva -->
                        <ngx-dropzone *ngIf="!course.ImgFileUrl || newImageSelected" (change)="onSelectImages($event)" [multiple]="false">
                            <ngx-dropzone-label class="label-file">Arrastra una imagen aquí o haz clic para seleccionar una imagen.</ngx-dropzone-label>
                          
                            <!-- Previsualización de la nueva imagen seleccionada -->
                            <ngx-dropzone-image-preview *ngFor="let f of imagesX" [file]="f" [removable]="true" (removed)="removeImage(f)">
                              <ngx-dropzone-label>{{ f.name.substr(1, 10) }}... / ({{ f.type }})</ngx-dropzone-label>
                            </ngx-dropzone-image-preview>
                        </ngx-dropzone>                          
                      
                        <!-- Mostrar el link de la imagen cargada si ya se subió -->
                        <section *ngIf="responseImgFile" style="margin-top: 10px;">
                          <a [href]="responseImgFile" target="_blank" style="color:#33bd38">Imagen cargada <i class="fa fa-check" aria-hidden="true"></i></a>
                        </section>
                      </div>                      

                    <button [disabled]="isButtonDisabled" (click)="saveProduct()" style="cursor: pointer; margin-top: 10px;" class="btn btn-sm btn-primary">Siguiente</button>
                </div>
                <div class="container" *ngIf="step === 1">
                    <mat-drawer-container class="example-container" [hasBackdrop]="hasBackdrop.value">
                        <mat-drawer #drawer [mode]="mode.value">I'm a drawer</mat-drawer>
                        <mat-drawer-content>
                            <mat-form-field>
                                <mat-label>Sidenav mode</mat-label>
                                <mat-select #mode value="side">
                                    <mat-option value="side">Side</mat-option>
                                    <mat-option value="over">Over</mat-option>
                                    <mat-option value="push">Push</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Has backdrop</mat-label>
                                <mat-select #hasBackdrop>
                                    <mat-option>Unset</mat-option>
                                    <mat-option [value]="true">True</mat-option>
                                    <mat-option [value]="false">False</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-raised-button (click)="drawer.toggle()">Toggle drawer</button>
                        </mat-drawer-content>
                    </mat-drawer-container>
                </div>
            </form>
        </div>
        <div class="col-12 col-md-9" *ngIf="step === 0">
            <div class="bg-white">
                <div class="browser-window w-100">
                    <div class="browser-header">
                        <div class="buttons">
                            <span class="button close"></span>
                            <span class="button minimize"></span>
                            <span class="button maximize"></span>
                        </div>
                        <div class="address-bar">
                            <input type="text" disabled [value]="course.Slug" readonly>
                        </div>
                    </div>
                    <div class="browser-content">
                        <div class="row">
                            <div class=" col-12 col-md-7">
                                <div class="course-detalis-wrapper mb-30">
                                    <div class="course-title-breadcrumb text-dark">
                                        <nav>
                                            <ol class="breadcrumb">
                                                <li class="breadcrumb-item-front"><a href="/">Inicio</a></li>
                                                <li class="breadcrumb-item-front"><span>{{ course.Title }}</span></li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <div class="course-heading mb-20">
                                        <h2>{{course.Title}}</h2>
                                    </div>
                                    <div class="course-detelis-meta" style="text-align: center;">
                                        <div class="course-meta-wrapper border-line-meta">
                                            <div class="course-meta-img">
                                                <a>
                                                    <img style="object-fit: cover;" *ngIf="currentUser?.imgProfile"
                                                        [src]="currentUser?.imgProfile" alt="course-meta">
                                                    <img style="object-fit: cover;" *ngIf="!currentUser?.imgProfile"
                                                        src="../../../../../assets/images/dashboard/man.png"
                                                        alt="course-meta">
                                                </a>
                                            </div>
                                            <div class="course-meta-text">
                                                <span>Creado Por</span>
                                                <h6><b>{{ currentUser?.displayName }}</b></h6>
                                            </div>
                                        </div>
                                        <div class="course-update border-line-meta">
                                            <p>Categoría</p>
                                            <b>{{ categorySelected?.ValueAlpha }}</b>
                                        </div>
                                        <div class="course-category">
                                            <p>Nivel</p>
                                            <b>{{ levelSelected?.ValueAlpha }}</b>
                                        </div>
                                    </div>
                                    <div class="course-description pt-4 pb-3">
                                        <div class="course-Description">
                                            <h4>Descripción</h4>
                                        </div>
                                        <p [innerHTML]="course.Description"></p>
                                    </div>
                                    <div class="course-learn-wrapper">
                                        <div class="course-learn">
                                            <div class="course-leranm-tittle">
                                                <h4 class="mb-8"><b>¿Qué Aprenderás?</b></h4>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div>
                                                        <ul>
                                                            @for (learn of learning; track learn) {
                                                            <li>
                                                                <i class="fa fa-check" aria-hidden="true"></i>
                                                                {{ learn.name }}
                                                            </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="course-requirements pt-5">
                                        <h4 class="title-4"><b>¿A quién va dirigido?</b></h4>
                                        <div class="course-requirements-text">
                                            <ul>
                                                @for (learn of target; track learn) {
                                                <li>
                                                    <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
                                                    {{ learn.name }}
                                                </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <div class="course-video-widget">
                                    <div class="course-widget-wrapper mb-30">
                                        <div class="course-video-thumb w-img">
                                            <img alt="image not found" *ngIf="responseImgFile === ''"
                                                src="https://tse2.mm.bing.net/th/id/OIG3.Xz0M5tRen4dQ6MqpQbHZ?pid=ImgGn">
                                            <img alt="image not found" *ngIf="responseImgFile !== ''"
                                                [src]="responseImgFile">
                                        </div>
                                        <div class="course-video-price">
                                            <div class="event-price-info">
                                                <div class="event-ticket-cost"><span>Precio:</span></div>
                                                <div class="event-price"><span>${{ course.UnitPrice | number: '1.2-2' }}
                                                        MXN</span>
                                                    <div class="old-price"><!----></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="course-video-body">
                                            <ul>
                                                <li>
                                                    <div class="course-vide-icon"><i
                                                            class="flaticon-filter"></i><span>Nivel</span></div>
                                                    <div class="video-corse-info"><span>{{ levelSelected?.ValueAlpha
                                                            }}</span></div>
                                                </li>
                                                <li>
                                                    <div class="course-vide-icon"><i
                                                            class="flaticon-menu-2"></i><span>Categoría</span></div>
                                                    <div class="video-corse-info"><span>{{ categorySelected?.ValueAlpha
                                                            }}</span></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>