import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgIf, NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ProductsService } from 'src/app/shared/service/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { map, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import * as Notiflix from 'notiflix';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

// import { GrapesjsComponent } from "./grapesjs/grapesjs.component";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { LandingCursoComponent } from '../landing-curso/landing-curso.component';
import { CreacionLandingService } from 'src/app/shared/service/creacion-landing.service';
import { ReadStream } from 'fs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatExpansionModule } from '@angular/material/expansion';
import { LandingsService } from 'src/app/shared/service/landings.service';
import { forkJoin } from 'rxjs';

export interface resultados {
  name: string;
}

export interface nombreCursos {
  name: string;
}

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [ReactiveFormsModule, MatExpansionModule, MatFormFieldModule, MatInputModule, CommonModule, MatStepperModule, MatFormFieldModule,
    MatInputModule,
    MatChipsModule, MatIconModule, LandingCursoComponent],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent implements OnInit {
  infoForm: FormGroup;
  productInfo: any;
  formSubmitted = false;
  tiposDeRecursos: string[] = ['Video', 'Ebook', 'Documento'];
  // tipoRecursoSeleccionado: string = '';
  selectedFile: File | null = null;
  selectedRecursoFile: File | null = null;
  selectedProductId: number | null = null;  // Aquí almacenamos el ProductId
  selectedStep = 0;
  addOnBlur = true;
  urls = [];
  stepperOrientation: Observable<StepperOrientation>;
  @ViewChild(MatStepper) stepper: MatStepper;
  crearPlantilla: boolean;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public resultados: any[] = [];

  public deseo: File | null = null; // Almacena el archivo
  public imagenUrl: string | ArrayBuffer | null = null; // Almacena la URL de la imagen
  public tipoRecursoSeleccionado: string = ''; // Almacena el tipo de recurso seleccionado
  public archivoSeleccionado: File | null = null; // Almacena el archivo seleccionado
  queObtendras: any[] = [];
  preguntasFrecuentes: any[] = [];

  selectedImage: any;
  imgObtendras: any;
  imagenUsuario: any;
  multipleImages: any;
  trackByFn: any;
  quienNosResplada: any;
  selectedImages: any;
  imagenesRespaldo: any;
  respuestas: any;
  respaldo: any;
  respaldoImages: File[] = [];
  imagenesRecRegalo: any;
  formPreguntas: any;
  form: any;
  preguntaRespuestaForm: any;
  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private ProductsService: ProductsService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private creacionLandingService: CreacionLandingService,
    private landingsService: LandingsService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.infoForm = this.fb.group({
      gancho: ['', Validators.required],
      micronicho: ['', Validators.required],
      propuesta: ['', Validators.required],
      deseo: ['', Validators.required],
      imagen: ['', Validators.required],
      imgObtendras: ['', Validators.required],
      imagenUsuario: ['', Validators.required],
      GatilloM: ['', Validators.required],
      contador: ['', Validators.required],
      resultadoCurso: ['', Validators.required],
      quienNosResplada: ['', Validators.required],
      recursosObsequio: ['', Validators.required],
      respuestas: ['', Validators.required],
      respaldo: ['', Validators.required],
      recursosRegalo: ['', Validators.required],
      imagenesRecRegalo: ['', Validators.required],
      // deseo: ['', Validators.required],
      // problema: ['', Validators.required],
      // descripcion: ['', Validators.required],
      // imagen: ['', Validators.required],
      tipoRecurso: ['', Validators.required],
      recurso: ['', Validators.required],
      somos: ['', Validators.required],
      FAQs: ['', Validators.required],
      quienNosRespalda: ['', Validators.required]
    });

    this.form = this.fb.group({
      preguntasRespuestas: this.fb.array([])
    });
  }
  gancho: any;
  resourceUrl: any;
  micronicho: any;
  problema: any;
  descripcion: any;
  propuesta: any;
  GatilloM: any;
  contador: any;
  resultadoCurso: any;
  somos: any;
  FAQs: any;
  ngOnInit(): void {
    // Capturamos el id del curso desde la URL
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.selectedProductId = Number(id);  // Asignamos el ProductId seleccionado
      }
    });

    this.preguntasRespuestas.valueChanges.subscribe(changes => {
      // console.log('Preguntas y Respuestas cambiaron:', changes);
      this.creacionLandingService.setPreguntasRespuestas(changes);
    });


  }
  saveProduct() {

    const objetive = this.resultados.map((t: any) => { return t.name })
  }

  get preguntasRespuestas(): FormArray {
    return this.form.get('preguntasRespuestas') as FormArray;
  }

  addResultado(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.resultados.push({ name: value.trim() });
    }

    if (input) {
      input.value = '';
    }
  }

  removeResultado(resultado: string): void {
    const index = this.resultados.indexOf(resultado);

    if (index >= 0) {
      this.resultados.splice(index, 1); // Elimina el resultado del arreglo
    }
  }
  removeImageRespald(i: number) {
    // console.log(i);
    this.creacionLandingService.removeImage(i);
    // console.log(this.imagenesRespaldo);
    // if(this.imagenesRespaldo.length == 0){
    //   this.imagenesRespaldo= null;
    //   this.infoForm.get('quienNosRespalda').reset();
    // }

  }

  removeImageRespald2(i: number) {
    // console.log(i);
    this.creacionLandingService.removeImage2(i);
    // console.log(this.imagenesRespaldo);
    // if(this.imagenesRespaldo.length == 0){
    //   this.imagenesRespaldo= null;
    //   this.infoForm.get('quienNosRespalda').reset();
    // }

  }
  // onFileSelected(event: Event) {
  //   const file = (event.target as HTMLInputElement).files[0];
  //   if (file) {
  //     this.deseo = file;

  //     // Crear una URL de objeto para mostrar la imagen
  //     const reader = new FileReader();
  //     reader.onload = () =>a {
  //       this.imagenUrl = reader.result; // Almacena la URL de la imagen
  //     };
  //     reader.readAsDataURL(file); // Leer la imagen como URL de datos
  //   }
  // }
  onFileSelected(event: any, type: string): void {
    const file = event.target.files[0];
    console.log('Archivo seleccionado:', file);

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'images');
      console.log('FormData preparado con el archivo:', formData);

      this.landingsService.uploadImg(formData).subscribe(
        (response) => {
          console.log('Respuesta del servidor:', response);
          const imageUrl = response.ImageUrl;

          if (type === 'imagenCurso') {
            this.selectedImage = imageUrl;
            console.log('Imagen de curso subida:', this.selectedImage);
          } else if (type === 'imgObtendras') {
            this.imgObtendras = imageUrl;
            console.log('Imagen "Obtendrás" subida:', this.imgObtendras);
          } else if (type === 'imagenUsuario') {
            this.imagenUsuario = imageUrl;
            console.log('Imagen de usuario subida:', this.imagenUsuario);
          } else if (type === 'recursosRegalo') {
            if (!this.imagenesRecRegalo) {
              this.imagenesRecRegalo = [];  // Inicializar si es undefined
            }
            this.imagenesRecRegalo.push(imageUrl);
            console.log('Imagen de recurso regalo añadida:', this.imagenesRecRegalo);
          } else if (type === 'respaldo') {
            if (!this.imagenesRespaldo) {
              this.imagenesRespaldo = [];  // Inicializar si es undefined
            }
            this.imagenesRespaldo.push(imageUrl);
            console.log('Imagen de respaldo añadida:', this.imagenesRespaldo);
          }
        },
        (error) => {
          console.error('Error al subir la imagen:', error);
        }
      );
    } else {
      console.warn('No se seleccionó ningún archivo.');
    }
  }

  sanitizeFile(file: File): SafeUrl {
    const url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }







  // onRecursoFileSelected(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   if (input.files && input.files.length > 0) {
  //     this.selectedRecursoFile = input.files[0];
  //     this.infoForm.patchValue({ recurso: this.selectedRecursoFile });
  //     this.infoForm.get('recurso')?.updateValueAndValidity();
  //     const file = input.files[0];
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     formData.append('type', 'images');
  //     this.ProductsService.uploadFile2(formData)
  //       .subscribe(resolve => {
  //         if (resolve.FileUrl !== null) {
  //           this.resourceUrl = resolve.FileUrl;
  //         }
  //       });
  //   }
  // }
  // onTipoRecursoChange(): void {
  //   this.tipoRecursoSeleccionado = this.infoForm.get('tipoRecurso')?.value;
  //   this.infoForm.get('recurso')?.setValue('');
  //   this.infoForm.get('recurso')?.updateValueAndValidity();
  // }
  onTipoRecursoChange(event: Event) {
    this.tipoRecursoSeleccionado = (event.target as HTMLSelectElement).value;
    // console.log(`Tipo de recurso seleccionado: ${this.tipoRecursoSeleccionado}`);
  }

  onFile(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      const formData = new FormData();
      formData.append('type', 'resources');
      formData.append('file', file); // Agregar el archivo al FormData

      console.log('Subiendo archivo...');

      // Llamar al método de servicio para subir el archivo
      this.landingsService.uploadResource(formData).subscribe(
        (response) => {
          // Aquí se asume que la respuesta contiene la URL del archivo subido
          this.archivoSeleccionado = response.ImageUrl; // Guardar la URL en lugar del archivo
          console.log('Archivo subido con éxito. URL:', this.archivoSeleccionado);
        },
        (error) => {
          console.error('Error al subir el archivo:', error);
          Notiflix.Notify.failure('Error al subir el archivo');
        }
      );
    }
  }

  mostrarInputRecurso(): boolean {
    return this.tipoRecursoSeleccionado === 'Video' || this.tipoRecursoSeleccionado === 'Ebook' || this.tipoRecursoSeleccionado === 'Documento';
  }
  isFieldInvalid(field: string): boolean {
    const control = this.infoForm.get(field);
    return control?.invalid && control?.touched;
  }
  onSubmit(): void {
    if (this.infoForm.valid && this.selectedProductId) {
      this.formSubmitted = true;
      const bodies = [
        { Property: "Landing-Micronicho", Value: this.infoForm.value.micronicho, ProductId: this.selectedProductId },
        { Property: "Landing-Propuesta", Value: this.infoForm.value.propuesta, ProductId: this.selectedProductId },
        { Property: "Landing-GatilloM", Value: this.infoForm.value.GatilloM, ProductId: this.selectedProductId },
        { Property: "Landing-contador", Value: this.infoForm.value.contador, ProductId: this.selectedProductId },
        { Property: "Landing-resultadoCurso", Value: this.infoForm.value.resultadoCurso, ProductId: this.selectedProductId },
        { Property: "Landing-Deseo", Value: this.infoForm.value.deseo, ProductId: this.selectedProductId },
        { Property: "Landing-Problema", Value: this.infoForm.value.problema, ProductId: this.selectedProductId },
        { Property: "Landing-Descripcion", Value: this.infoForm.value.descripcion, ProductId: this.selectedProductId },
        // { Property: "Landing-Imagen", Value: this.imagenUrl, ProductId: this.selectedProductId },
        { Property: "Landing-Tipo", Value: this.infoForm.value.tipoRecurso, ProductId: this.selectedProductId },
        { Property: "Landing-Recurso", Value: this.resourceUrl, ProductId: this.selectedProductId },
        { Property: "Landing-somos", Value: this.infoForm.value.somos, ProductId: this.selectedProductId },
        { Property: "Landing-FAQS", Value: this.infoForm.value.FAQs, ProductId: this.selectedProductId },

      ];
      // Enviar la solicitud al backend con el ProductId
      this.ProductsService.enviarloanding(bodies).subscribe(
        (response) => {
          // console.log('Formulario enviado correctamente', response);
          Swal.fire({
            title: 'Éxito!',
            text: 'El formulario se ha enviado correctamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.router.navigate(['/products/courses']);  // Redirige a la URL deseada
          });
        },
        (error) => {
          console.error('Error al enviar el formulario', error);
          Swal.fire({
            title: 'Error!',
            text: 'Ocurrió un problema al enviar el formulario.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      );
    } else {
      // console.log('El formulario o el ProductId es inválido');
      this.formSubmitted = false;
    }
  }
  goToNextStep(e: any) {
    this.stepper.next();
    if (e == 1 || e == 2) {
      Notiflix.Notify.success('Plantilla seleccionada');
      this.crearPlantilla = false;
    } else {
      this.crearPlantilla = true;
    }
  }

  remove(fruit): void {
    const index = this.queObtendras.indexOf(fruit);

    if (index >= 0) {
      this.queObtendras.splice(index, 1);
      this.creacionLandingService.updateQueObtendras(this.queObtendras);
      // this.updatePromptPalabrasClave();
    }
    // this.actionName = false;
    // this.botonGuardarAvatar = false;
  }

  add(event: MatChipInputEvent): void {
    // console.log(event.value);
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.queObtendras.push({ name: value.trim() });
      this.creacionLandingService.updateQueObtendras(this.queObtendras);
      // this.updatePromptPalabrasClave();
      // this.botonGuardarAvatar = false;
    }
    // console.log(this.queObtendras);

    // Reset the input value
    if (input) {
      input.value = '';
    }
    // this.actionName = false;
  }
  removeImage(type): void {
    if (type == 'imagenCurso') {
      this.selectedImage = null;
      this.infoForm.get('imagen').reset();
    } else if (type == 'imgObtendras') {
      this.imgObtendras = null;
      this.infoForm.get('imgObtendras').reset();
    } else if (type == 'imagenUsuario') {
      this.imagenUsuario = null;
      this.infoForm.get('imagenUsuario').reset();
    }
  }
  addImage(): void {
    if (this.respaldo) {
      this.respaldoImages.push(this.respaldo);
      // console.log(this.respaldoImages);
      this.removeImage('respaldo'); // Opcional: limpiar el campo después de agregar la imagen
    }
  }

  removeImageMulty(index: number): void {
    this.multipleImages.splice(index, 1);
  }

  addPreguntaRespuesta(): void {
    this.preguntaRespuestaForm = this.fb.group({
      pregunta: ['',],
      respuesta: ['',]
    });

    this.preguntasRespuestas.push(this.preguntaRespuestaForm);
    // console.log(this.preguntasRespuestas);
  }

  removePreguntaRespuesta(index: number): void {
    this.preguntasRespuestas.removeAt(index);
  }

  comprobarDatos() {

  }

  generarLanding() {
    let gancho = this.gancho;
    let propuesta = this.propuesta;
    let deseo = this.deseo;
    let imagenCurso = this.selectedImage;
    let queObtendrasString = this.queObtendras.map(item => item.name).join(',');
    let imagenObtendras = this.imgObtendras;
    let imagenesRespaldo = this.imagenesRespaldo.length ? this.imagenesRespaldo.join(',') : 'Sin imágenes de respaldo';
    let recursosRegaloString = this.imagenesRecRegalo.length ? this.imagenesRecRegalo.join(',') : 'Sin recursos de regalo';
    let somos = this.somos;
    let preguntasRespuestas = this.preguntasRespuestas.value;
    let preguntasString = preguntasRespuestas
      .map(item => item.pregunta)
      .filter(pregunta => pregunta)
      .join('-');
    let respuestas = this.preguntasRespuestas.value;
    let respuestaString = respuestas
      .map(item => item.respuesta)
      .filter(respuesta => respuesta)
      .join('-');
    let archivo = this.archivoSeleccionado;
    let imagenUsuario = this.imagenUsuario;
    // Crear un array de las peticiones a enviar
    const requests = [
      this.landingsService.setProductInfo({ Property: "Landing-Gancho", Value: gancho, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-Propuesta", Value: propuesta, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-Deseo", Value: deseo, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-Imagen", Value: imagenCurso, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-QueObtendras", Value: queObtendrasString, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-GM", Value: 'De $99 USD a tan solo $27 USD, por tiempo limitado.', ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-ImgObtendras", Value: imagenObtendras, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-Respaldo", Value: imagenesRespaldo, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-Somos", Value: somos, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-Recursos", Value: recursosRegaloString, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-Pregunta", Value: preguntasString, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-Respuesta", Value: respuestaString, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-Archivo", Value: archivo, ProductId: this.selectedProductId }),
      this.landingsService.setProductInfo({ Property: "Landing-ImagenUsuario", Value: imagenUsuario, ProductId: this.selectedProductId }),
    ];

    // Ejecutar todas las peticiones en paralelo y esperar a que todas terminen
    forkJoin(requests).subscribe(
      (results) => {
        // Mostrar la notificación de éxito
        Notiflix.Notify.success('Guardado con éxito');
        console.log(results);
      },
      (error) => {
        console.error('Error al guardar los datos:', error);
        Notiflix.Notify.failure('Error al guardar los datos');
      }
    );
  }
}