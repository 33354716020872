<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-container">
                        <!-- <div class="profile-details text-center position-relative"> -->
                        <div class="profile-container text-center">
                            <div class="profile-image-container">
                                <img [src]="profileImageUrl" alt="profile image" class="profile-image">

                                <!-- Icono de cámara superpuesto -->
                                <div class="camera-icon-container" (click)="triggerFileInput()">
                                    <i class="fa fa-camera" aria-hidden="true"></i>
                                </div>
                            </div>
                            <!-- <div class="profile-details text-center">  -->

                            <h5 class="f-w-600 mb-0">{{ profile?.displayName }}</h5>
                            <span>{{ profile?.email }}</span>


                            <!-- <div class="social-icons mt-3">
                                <div class="form-group btn-showcase">
                                    <a href="https://facebook.com/tuPerfil" target="_blank"
                                        class="btn social-btn btn-fb d-inline-block">
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                    <a href="https://google.com" target="_blank"
                                        class="btn social-btn btn-google d-inline-block">
                                        <i class="fa fa-google"></i>
                                    </a>
                                    <a href="https://twitter.com/tuPerfil" target="_blank"
                                        class="btn social-btn btn-twitter d-inline-block me-0">
                                        <i class="fa fa-twitter"></i>
                                    </a>

                                </div>
                            </div> -->
                        </div>
                        <hr>
                        <!-- <div class="project-status">
                            <h5 class="f-w-600">Estado del Empleado</h5>
                            <div class="media">
                                <div class="media-body">
                                    <h6>Desempeño<span class="pull-right">80%</span></h6>
                                    <div class="progress sm-progress-bar">
                                        <div class="progress-bar bg-primary" role="progressbar" style="width: 90%"
                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="media">
                                <div class="media-body">
                                    <h6>Horas extras<span class="pull-right">60%</span></h6>
                                    <div class="progress sm-progress-bar">
                                        <div class="progress-bar bg-secondary" role="progressbar" style="width: 60%"
                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="media">
                                <div class="media-body">
                                    <h6>Dias de ausencia<span class="pull-right">70%</span></h6>
                                    <div class="progress sm-progress-bar">
                                        <div class="progress-bar bg-danger" role="progressbar" style="width: 70%"
                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <input type="file" id="fileInput" (change)="onFileSelected($event)" style="display: none;">
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <mat-tab-group>

                        <!-- Pestaña Generales -->
                        <mat-tab label="Generales">
                            <div class="w-100 d-flex justify-content-end pb-3">
                                <button (click)="saveProfile()" class="btn btn-secondary">{{ onEdit ? 'Guardar' :
                                    'Editar' }}</button>
                            </div>
                            <div class="table-responsive profile-table">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td width="30%">Nombre(s):</td>
                                            <td width="70%">
                                                <span *ngIf="!onEdit">{{ profile?.firstName }}</span>
                                                <mat-form-field appearance="outline" class="full-width mb-0 pb-0"
                                                    *ngIf="onEdit">
                                                    <mat-label>Nombre</mat-label>
                                                    <input matInput type="text" required
                                                        [(ngModel)]="profile.firstName">
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="30%">Apellido(s):</td>
                                            <td width="70%">
                                                <span *ngIf="!onEdit">{{ profile?.lastName }}</span>
                                                <mat-form-field appearance="outline" class="full-width mb-0 pb-0"
                                                    *ngIf="onEdit">
                                                    <mat-label>Nombre</mat-label>
                                                    <input matInput type="text" required [(ngModel)]="profile.lastName">
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="30%">Correo eléctronico:</td>
                                            <td width="70%">
                                                <span *ngIf="!onEdit">{{ profile?.email }}</span>
                                                <mat-form-field appearance="outline" class="full-width mb-0 pb-0"
                                                    *ngIf="onEdit">
                                                    <mat-label>Correo eléctronico</mat-label>
                                                    <input matInput type="text" required [(ngModel)]="profile.email">
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="30%">Teléfono:</td>
                                            <td width="70%">
                                                <span *ngIf="!onEdit">{{ profile?.phoneNumber }}</span>
                                                <mat-form-field appearance="outline" class="full-width mb-0 pb-0"
                                                    *ngIf="onEdit">
                                                    <mat-label>Número teléfonico</mat-label>
                                                    <input matInput type="text" required
                                                        [(ngModel)]="profile.phoneNumber">
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="30%">Biografía:</td>
                                            <td width="70%">
                                                <span *ngIf="!onEdit">{{ profile?.biography }}</span>
                                                <mat-form-field appearance="outline" class="full-width mb-0 pb-0"
                                                    *ngIf="onEdit">
                                                    <mat-label>Biografía</mat-label>
                                                    <textarea matInput type="text" required
                                                        [(ngModel)]="profile.biography"></textarea>
                                                </mat-form-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </mat-tab>
                        <mat-tab label="Educación">
                            <form (ngSubmit)="guardarAntecedente()">
                                <div class="mb-3">
                                    <label for="ultimoGradoEstudios" class="form-label">Grado de
                                        estudios</label>
                                    <select class="form-select" id="ultimoGradoEstudios" [(ngModel)]="profile.grado"
                                        #ultimoGradoEstudiosInput="ngModel" name="ultimoGradoEstudios" required>
                                        <option [ngValue]="null" disabled selected class="select-placeholder">
                                            Seleccione su grado de estudios*</option>
                                        <option value="primaria">Primaria</option>
                                        <option value="secundaria">Secundaria</option>
                                        <option value="preparatoria">Preparatoria</option>
                                        <option value="universidad">Universidad</option>
                                        <option value="maestria">Maestría</option>
                                        <option value="doctorado">Doctorado</option>
                                    </select>
                                    <div *ngIf="!profile.grado" class="text-danger">
                                        Este campo es obligatorio.
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="carrera" class="form-label">Carrera</label>
                                    <input type="text" class="form-control placeholder-text" id="carrera"
                                        placeholder="Carrera*" [(ngModel)]="profile.carrera" name="carrera" required
                                        #carreraInput="ngModel">
                                    <div *ngIf="carreraInput.invalid && carreraInput.touched" class="text-danger">
                                        Este campo es obligatorio.
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="escuela" class="form-label">Escuela</label>
                                    <input type="text" class="form-control placeholder-text" id="escuela"
                                        placeholder="Escuela*" [(ngModel)]="profile.escuela" name="escuela" required
                                        #escuelaInput="ngModel">
                                    <div *ngIf="escuelaInput.invalid && escuelaInput.touched" class="text-danger">
                                        Este campo es obligatorio.
                                    </div>
                                </div>
                                <div class="w-100 d-flex justify-content-end">
                                    <button (click)="saveEducation()" class="btn btn-secondary">Guardar</button>
                                </div>
                                <!-- <div class="mb-3">
                                    <label for="aboutMe" class="form-label">Biografía</label>
                                    <textarea class="form-control placeholder-text" id="aboutMe" rows="3"
                                        maxlength="300" placeholder="Máximo 300 caracteres*" [(ngModel)]="aboutMe"
                                        name="aboutMe" required #aboutMeInput="ngModel"
                                        (input)="checkCharacterLimit()"></textarea>
                                    <div *ngIf="aboutMeInput.invalid && aboutMeInput.touched" class="text-danger">
                                        Este campo es obligatorio.
                                    </div>
                                    <div *ngIf="aboutMe?.length >= 300" class="text-danger">
                                        Has alcanzado el límite de 300 caracteres.
                                    </div>
                                </div> -->
                                <!-- <div class="mb-3">
                                        <label for="fbUrl" class="form-label">Facebook</label>
                                        <input type="url" class="form-control placeholder-text" id="fbUrl" placeholder="https://facebook.com/tuPerfil*"
                                               [(ngModel)]="fbUrl" name="fbUrl"required #fbUrlInput="ngModel">
                                               <div *ngIf="fbUrlInput.invalid && fbUrlInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                              </div>    
                                    </div>
                                    <div class="mb-3">
                                        <label for="xUrl" class="form-label">X (Twitter)</label>
                                        <input type="url" class="form-control placeholder-text" id="xUrl" placeholder="https://twitter.com/tuPerfil*"
                                               [(ngModel)]="xUrl" name="xUrl"required #xUrlInput="ngModel">
                                               <div *ngIf="xUrlInput.invalid && xUrlInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                              </div>  
                                    </div>
                                    <div class="mb-3">
                                        <label for="lnUrl" class="form-label">LinkedIn</label>
                                        <input type="url" class="form-control placeholder-text" id="lnUrl" placeholder="https://linkedin.com/in/tuPerfil*"
                                               [(ngModel)]="lnUrl" name="lnUrl"required #lnUrlInput="ngModel">
                                               <div *ngIf="lnUrlInput.invalid && lnUrlInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                              </div>  
                                    </div> -->
                                <!-- <div class="mb-3">
                                    <label class="form-label" style="font-size: 1rem; font-weight: bold;"></label>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Empresa</th>
                                                <th scope="col">Cargo</th>
                                                <th scope="col">Periodo de Inicio</th>
                                                <th scope="col">Periodo de Fin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let antecedente of antecedentes">
                                                <td>{{ antecedente.empresa }}</td>
                                                <td>{{ antecedente.cargo }}</td>
                                                <td>{{ antecedente.periodoInicio }}</td>
                                                <td>{{ antecedente.periodoFin }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="button-container" style="display: flex; gap: 20px;">
                                        <button type="button" class="btn btn-primary"
                                            (click)="mostrarFormulario(dialogTemplate)">Agregar
                                            Experiencia</button>
                                        <form (ngSubmit)="enviarDatosPerfil()" #updateProfile="ngForm">
                                            <button type="submit" class="btn btn-secondary"
                                                [disabled]="!updateProfile.valid">Enviar</button>
                                        </form>
                                    </div>
                                    <div *ngIf="formSubmitted" class="alert alert-success mt-3">¡Formulario
                                        enviado correctamente!</div>
                                </div>
                                <ng-template #dialogTemplate>
                                    <div class="form-container">
                                        <h6 class="f-w-600"></h6>
                                        <div class="mb-3">
                                            <label for="empresa" class="form-label">Empresa</label>
                                            <input type="text" class="form-control placeholder-text" id="empresa"
                                                placeholder="Empresa*" [(ngModel)]="nuevoAntecedente.empresa"
                                                name="empresa" required #empresaInput="ngModel">
                                            <div *ngIf="empresaInput.invalid && empresaInput.touched"
                                                class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="cargo" class="form-label">Cargo</label>
                                            <input type="text" class="form-control placeholder-text" id="cargo"
                                                placeholder="Cargo*" [(ngModel)]="nuevoAntecedente.cargo" name="cargo"
                                                required #cargoInput="ngModel">
                                            <div *ngIf="cargoInput.invalid && cargoInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="periodoInicio" class="form-label">Periodo de
                                                Inicio</label>
                                            <input type="date" class="form-control" id="periodoInicio"
                                                [(ngModel)]="nuevoAntecedente.periodoInicio" name="periodoInicio"
                                                required #periodoInicioInput="ngModel">
                                            <div *ngIf="periodoInicioInput.invalid && periodoInicioInput.touched"
                                                class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="periodoFin" class="form-label">Periodo de
                                                Fin</label>
                                            <input type="date" class="form-control" id="periodoFin"
                                                [(ngModel)]="nuevoAntecedente.periodoFin" name="periodoFin">
                                        </div>
                                        <style>
                                            .modal-footer {
                                                display: flex;
                                                justify-content: flex-end;
                                                gap: 230px;
                                            }
                                        </style>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-success"
                                                (click)="guardarAntecedente()">Guardar</button>
                                            <button type="button" class="btn btn-secondary"
                                                (click)="dialog.closeAll()">Cerrar</button>

                                        </div><br>
                                    </div>
                                </ng-template> -->
                            </form>
                        </mat-tab>
                        <mat-tab label="Experiencia Laboral">
                            <div class="row">
                                <!-- Columna del formulario -->
                                <div class="col-md-6">
                                    <h6 class="f-w-600"></h6>
                                    <form (ngSubmit)="guardarAntecedente()">
                                        <div class="mb-3">
                                            <label for="empresa" class="form-label">Empresa</label>
                                            <input type="text" class="form-control placeholder-text" id="empresa"
                                                placeholder="Empresa*" [(ngModel)]="nuevoAntecedente.empresa"
                                                name="empresa" required #empresaInput="ngModel">
                                            <div *ngIf="empresaInput.invalid && empresaInput.touched"
                                                class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <label for="cargo" class="form-label">Cargo</label>
                                            <input type="text" class="form-control placeholder-text" id="cargo"
                                                placeholder="Cargo*" [(ngModel)]="nuevoAntecedente.cargo" name="cargo"
                                                required #cargoInput="ngModel">
                                            <div *ngIf="cargoInput.invalid && cargoInput.touched" class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <label for="periodoInicio" class="form-label">Periodo de
                                                Inicio</label>
                                            <input type="date" class="form-control" id="periodoInicio*"
                                                [(ngModel)]="nuevoAntecedente.periodoInicio" name="periodoInicio"
                                                required #periodoInicioInput="ngModel">
                                            <div *ngIf="periodoInicioInput.invalid && periodoInicioInput.touched"
                                                class="text-danger">
                                                Este campo es obligatorio.
                                            </div>
                                        </div>

                                        <div class="mb-3">
                                            <label for="periodoFin" class="form-label">Periodo de Fin</label>
                                            <input type="date" class="form-control" id="periodoFin*"
                                                [(ngModel)]="nuevoAntecedente.periodoFin" name="periodoFin">
                                        </div>

                                        <!-- <div class="button-container" style="display: flex; gap: 20px;">
                                                        <button type="button" class="btn btn-success" (click)="guardarAntecedente()">Guardar</button>
                                                    </div> -->
                                    </form>
                                </div>

                                <!-- Columna de la tabla de experiencia -->
                                <div class="col-md-6">
                                    <label class="form-label" style="font-size: 1rem; font-weight: bold;"></label>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 25%;">Empresa</th>
                                                <th scope="col" style="width: 25%;">Cargo</th>
                                                <th scope="col" style="width: 25%;">Inicio</th>
                                                <th scope="col" style="width: 25%;">Fin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- Mostrar la lista de antecedentes -->
                                            <tr *ngFor="let antecedente of antecedentes">
                                                <td>{{ antecedente.empresa }}</td>
                                                <td>{{ antecedente.cargo }}</td>
                                                <td>{{ antecedente.periodoInicio }}</td>
                                                <td>{{ antecedente.periodoFin }}</td>
                                            </tr>
                                            <!-- Mostrar los datos ingresados actualmente en el formulario -->
                                            <tr
                                                *ngIf="nuevoAntecedente.empresa || nuevoAntecedente.cargo || nuevoAntecedente.periodoInicio">
                                                <td>{{ nuevoAntecedente.empresa }}</td>
                                                <td>{{ nuevoAntecedente.cargo }}</td>
                                                <td>{{ nuevoAntecedente.periodoInicio }}</td>
                                                <td>{{ nuevoAntecedente.periodoFin }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>