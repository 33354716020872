import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../utils/headers.functions';
import { Observable, tap } from 'rxjs';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class LandingsService {

  constructor(private http: HttpClient) { }


  getLandinPage(slug: any){
    return this.http.get<any>(`${BASE_URL}/landing/${slug}`);
  }

  public uploadImg(formData: any): Observable<any> {
    console.log('Enviando imagen al servidor...');
    const headers = getHeaders();  // Asegúrate de que este método retorne los headers necesarios
    return this.http.post<any>(`${BASE_URL}/uploadfile`, formData).pipe(
      tap(response => console.log('Respuesta recibida en uploadImg:', response))
    );
  }
  
  public uploadResource(formData: any): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/uploadfile`, formData, { headers });
  }

  setProductInfo(body: any){
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/info-product`, body, { headers });
}
}
