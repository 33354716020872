<div class="container">
    <div class="mt-20 mb-20" style="text-align: center;">
      <img src="../../../../assets/images/logo1.png" style="width: 30%;">
    </div>
    <section class="mt-20">
      <h1 style="color: #584294; font-weight: 800; font-size: 36px;">{{ gancho }}</h1>
      <h3>
        {{propuesta}}
      </h3>
  
    </section>
    <section class="main-section pad-70" style="margin-top: 1rem;">
      <div class="head-line">
        <h1 style="font-size: 36px; font-weight: 800;">{{ deseo }}</h1>
        <p class="mt-10 mb-10" style="color:#584294; font-weight: bolder; font-size: large;">De <strong style="color: red;"><del>$500</del></strong> USD a tan solo <strong style="color: red;">$399</strong> USD, por tiempo limitado.</p>
        <p><strong>Garantía de devolución de dinero sin riesgo durante 7 días.</strong></p>
    
        <!-- Temporizador de cuenta regresiva -->
        <div class="countdown-timer" style="font-size: 1.6rem; font-weight: bold; margin-bottom: 1rem;">
          <h3>Tiempo restante:</h3><h2 style="color: red;">00:00:00</h2>
        </div>
        <div>
          <button style="margin-bottom: 1rem;" class="edu-btnTransition">Ver Curso</button>
        </div>
      </div>
    
      <div [ngClass]="{'no-image-background image-container': !selectedImage}">
        <img [src]="selectedImage" alt="ImagenCurso" *ngIf="selectedImage"/>
        <span *ngIf="!selectedImage">No hay imagen cargada</span>
      </div>
    </section>
  
    <!-- <section class="benefit-section pad-70" style="margin-top: 2rem; margin-bottom: 2rem;">
      <div class="benefit-data">
        <h2>
          {{userData.name}}
        </h2>
      </div>
      <p style="line-height: 1.2;">
        {{userData.biography}}
      </p>
    </section> -->
    <!-- <section class="cta-section" style="margin-bottom: 2rem;"> -->
    <!-- <btn-primary btn-text="Fale com nossos especialistas"></btn-primary> -->
    <!-- <button class="edu-btnTransition" (click)="redirectRecurso()">Ver presentación</button>
    </section> -->
  
    <section class="main-section pad-70" style="margin-top: 2rem; margin-bottom: 2rem;">
      <div class="container">
        <h2 style="text-align: center;" class="mt-20 mb-30">¿Qué obtendrás en nuestro curso?</h2>
        <div class="row">
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-5" style="width: 50%;">
            <div class="head-line">
              <p>• Acceso vitalicio a nuestro curso donde aprenderás: </p>
              <ul>
                <li *ngFor="let obtendras of queObtendras">
                  <div class="">
                    <i class="fa fa-check" style="margin-right: 1rem; color: #D96C70; margin-bottom: 1rem;"></i>
                    {{ obtendras.name }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-7" style="align-content: center; width: 50%; text-align: center;" [ngClass]="{'no-image-background': !imgObtendras}">
              <img [src]="imgObtendras" alt="ImagenCurso" *ngIf="imgObtendras" style="width: 70%;"/>
              <span *ngIf="!imgObtendras">No hay imagen cargada</span>
          </div>
        </div>
      </div>
    </section>
    
    <div style="text-align: center; margin-bottom: 2rem;">
      <button style="margin-bottom: 1rem;" class="edu-btnTransition" >Ver Presentación</button>
    </div>
  </div>
  <div>
    <div class="container">
      <section style="text-align: center;">
        <img src="assets\images\dashboard\garantia.png">
        <h2>Garantía de devolución de dinero sin riesgo por 7 días.</h2>
        <p>Estamos tan seguros de que nuestro producto transformará tu negocio que ofrecemos una <strong>garantía de
            devolución de dinero de 7 días sin preguntas</strong>. Si no ves resultados, te devolvemos tu inversión.</p>
      </section>
    </div>
  </div>
  
  <div class="container">
    <section class="mt-60 mb-60">
      <div>
        <h1 class="mb-20" style=" text-align: center;">Imagina cuánto tiempo, dinero y libertad representan todas estas horas.</h1>
        <ul>
          <li *ngFor="let deseo of deseos">
            <i class="fa fa-check" style="margin-right: 1rem; color: #D96C70"></i>{{ deseo }}
          </li>
        </ul>
        <p style="text-align: center; color:#584294; font-weight: bolder"></p><!--{{ getValueByProperty('Landing-GM') }}-->
        <p style="text-align: center;"><strong>Garantía de devolución de dinero sin riesgo durante 7 días</strong></p>
      </div>
    </section>
  </div>
  
  <section class="form-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 style="color: white; text-align: center;">¿Quienés nos respaldan?</h1>
        </div>
      </div>
      
        <!-- <div *ngIf="imagenesRespaldan.length > 0"> -->
      <div class="row" >
        <img *ngFor="let imagen of imagenesRespaldo" [src]="imagen" alt="Imagen de respaldo" class="img-fluid" style="width: 20%;"/>
      </div>
        <!-- </div> -->
      
    </div>
  </section>
  



  <div class="container">
    <section class="main-section pad-70 mb-60" style="margin-top: 1rem;">
      <div class="head-line col-md-8">
        <h1>¿Quiénes somos?</h1>
        <!-- <h3>{{userData.name}}</h3> -->
        <!-- <p>{{ getValueByProperty('Landing-Somos') }}</p> -->
         <div>
           <p>{{somos}}</p>
         </div>
        <button style="margin-bottom: 1rem;" class="edu-btnTransition" >Contáctanos por
          Whatsapp</button>
      </div>
      <div class=" col-md-4 " [ngClass]="{'no-image-background image-container': !imagenUsuario}">

        <!-- <img class="img-fluid" [src]="imagenUsuario" alt="ImgUsuario"/> -->

        <!-- <div class="col-md-6 " style="align-content: center;" > -->
          <img class="img-fluid" [src]="imagenUsuario" alt="ImagenCurso" *ngIf="imagenUsuario"/>
          <span *ngIf="!imagenUsuario">No hay imagen cargada</span>
        <!-- </di[ngClass]="{'no-image-background': !imgObtendras}"v> -->
      </div>
    </section>
  </div>
  
  <section class="form-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 style="color: white; text-align: center;">¿Te quedaste con
            ganas de más?</h1>
          <h2 class="mb-20 mt-10" style="font-size: 1.5rem; text-align: center;">Y por si pensabas que era poco, te vamos
            a obsequiar los siguientes recursos.</h2>
        </div>
      </div>
      <div class="row">
        <img *ngFor="let imagen of imagenesRecRegalo" [src]="imagen" alt="Imagen de respaldo" class="img-fluid" style="width: 20%;" />
      </div>
      <div class="countdown-timer" style="font-size: 1.6rem; font-weight: bold; margin-bottom: 1rem; text-align: center;">   
        <h3 style="color: white; margin: 0;">Tiempo restante:</h3><h2 style="color: red;">{{countdown}}</h2>
      </div>
      <div style="text-align: center;">
        <button style="margin-bottom: 1rem;" class="edu-btnTransition">Aprovecha ahora</button>
      </div>
    </div>
  </section>
  
  
  
  <!-- 
  <section class="form-section" >
    <div class="text">
    
      <p style="color: white;">
        {{ getValueByProperty('Landing-Descripcion') }}
      </p>
    </div>
    <form id="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <label for="name">Name</label>
      <input id="name" formControlName="name" />
      
      <label for="email">E-mail</label>
      <input id="email" formControlName="email" />
  
      <label for="tel">Telefono</label>
      <input id="tel" formControlName="tel" />
      
      <button [disabled]="form.invalid"  type="submit" class="edu-btnTransition">Enviar</button>
      
    </form>
  </section> -->
  
  
  <div class="container mt-30 mb-30">
    <div class="row">
      <div class="col-md-6" style="align-content: center;">
        <div></div>
        <h1>¿Tienes Preguntas?</h1>
        <p style="font-size: 16px;">Si no encuentras la respuesta en esta pagina no dudes en contactarnos
        </p>
        <button class="edu-btnTransition mb-20">Whatsapp</button>
      </div>


      <mat-accordion>
        <mat-expansion-panel *ngFor="let item of preguntasRespuestas">
          <mat-expansion-panel-header>
            <mat-panel-title> {{ item.pregunta }} </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ item.respuesta }}</p>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </div>