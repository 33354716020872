<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 card-left hub-center">
                    <img src="../../../../assets/images/logo1.png" style="width:100%" alt="">
                    <img src="../../../../assets/images/backoffice.png" style="width:100%" alt="">
                </div>
                <div class="col-md-7 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>Iniciar sesión</a>
                                    <ng-template ngbNavContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="loginForm"
                                                class="form-horizontal auth-form needs-validation">
                                                <div class="form-group">
                                                    <input
                                                        [ngClass]="{'error': submittedLogin && loginForm.controls.email.hasError('required') }"
                                                        formControlName="email" type="email" class="form-control"
                                                        placeholder="Correo electrónico" id="exampleInputEmail1">
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        [ngClass]="{'error': submittedLogin && loginForm.controls.password.hasError('required') }"
                                                        formControlName="password" type="password" class="form-control"
                                                        placeholder="Contraseña">
                                                </div>
                                                <!-- <div class="form-terms">
                                                    <div
                                                        class="custom-control custom-checkbox d-flex align-items-center flex-wrap">
                                                        <a href="javascript:void(0)"
                                                            class="btn btn-default forgot-pass ms-auto"
                                                            (click)="nav.select(2)">¿Olvidaste tu
                                                            contraseña?</a>
                                                    </div>
                                                </div> -->
                                                <div class="form-button">
                                                    <button class="btn btn-primary" (click)="onSubmit()" type="submit" style="margin-top: 0px;">
                                                        Iniciar sesión</button>
                                                </div>
                                            </form>
                                        </div>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="2">
                                    <a ngbNavLink>¿Olvidaste tu contraseña?</a>
                                    <ng-template ngbNavContent>
                                        <div class="tab-pane fade active show" id="forgot-password" role="tabpanel"
                                            aria-labelledby="forgot-password-tab">
                                            <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()"
                                                class="form-horizontal auth-form needs-validation">
                                                <p style="color: black; text-align: left;">Por favor, escriba su correo electrónico.</p>
                                                <div class="form-group">
                                                    <input
                                                        [ngClass]="{'error': submittedForgot && forgotPasswordForm.controls.email.hasError('required') }"
                                                        formControlName="email" type="email" class="form-control"
                                                        placeholder="Ingrese su correo electrónico" id="forgotEmail">
                                                </div>
                                                <div class="form-button">
                                                    <button class="btn btn-primary" (click)="forgotPassword()" type="submit"
                                                        [disabled]="isSubmitting" style="margin-top: 0px;">
                                                        <!-- Mostrar spinner cuando isSubmitting sea true -->
                                                        <span *ngIf="isSubmitting" class="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        <!-- Texto del botón cambia según el estado -->
                                                        <span *ngIf="!isSubmitting">Enviar</span>
                                                        <span *ngIf="isSubmitting">Enviando...</span>
                                                    </button>
                                                </div>
                                                <!-- <div class="form-terms mt-3">
                                                    <a href="javascript:void(0)"
                                                        class="btn btn-link" (click)="nav.select(1)">
                                                        Volver a iniciar sesión
                                                    </a>
                                                </div> -->
                                            </form>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
