<div class="container-fluid">
    <div class="card">
        <div class="row product-page-main card-body">
            <div class="col-xl-4">
                <img [src]="productInfo?.ImgFileUrl" width="100%" alt="">
                <div class="w-100 d-flex justify-content-between mt-3">
                    <a href="{{productInfo?.ImgFileUrl}}" target="_blank" download="" class="mb-2 btn btn-primary">
                        <i class="fa fa-download m-0"></i> Descargar
                    </a>
                    <button (click)="imageFile.click()" class="mb-2 lm-2 btn btn-primary">
                        <i class="fa fa-upload m-0"></i> Reemplazar
                    </button>
                </div>
                <input type="file" style="display: none;" (change)="onSelectImages($event)" #imageFile>
            </div>
            <div class="col-xl-8">
                <div class="product-page-details product-right mb-0">
                    <h2 class="d-flex justify-content-between">{{ productInfo?.Title }} <button class="btn"
                            [ngClass]="{'btn-primary' : productInfo?.Active, 'btn-secondary' : !productInfo?.Active,}">{{
                            productInfo.Active ? 'Publicado' : 'En revisión' }}</button></h2>
                    <h6 class="product-title">Categoría</h6>
                    <p class="mb-0">{{ productInfo?.Category.ValueAlpha }}</p>
                    <h6 class="product-title">Nivel</h6>
                    <p class="mb-0">{{ productInfo?.Level.ValueAlpha }}</p>
                    <div class="product-price digits mt-2">
                        <h3>{{ productInfo?.UnitPrice | currency: 'USD' }}</h3>
                    </div>
                    <hr>
                    <h6 class="product-title">Descripción</h6>
                    <p class="mb-0">{{ productInfo?.Description }}</p>
                    <h6 class="product-title">¿Qué aprenderas?</h6>
                    <p class="mb-0">{{ productInfo?.Objective }}</p>
                    <h6 class="product-title">¿A quién va dirigido?</h6>
                    <p class="mb-0">{{ productInfo?.TargetMarket }}</p>
                </div>
            </div>
            <div>
                <hr>
                <div class="product-price digits mt-2">
                    <h2 style="font-size: 28px;" class="text-center">Plan de Estudio</h2>
                    <cdk-accordion class="example-accordion">
                        <ng-container *ngFor="let module of productInfo?.product; let i = index">
                          <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="region" tabindex="0"
                              id="accordion-header-{{ i }}" [attr.aria-expanded]="accordionItem.expanded"
                              [attr.aria-controls]="'accordion-body-' + i">
                              
                            <div class="example-accordion-item-header2 fontArial" (click)="accordionItem.toggle()">
                              <div class="module-title2">
                                <span class="module-title-text">MÓDULO {{ i }} - {{ module.Name }}</span>
                              </div>
                              <mat-icon style="line-height: 35px;">
                                {{ accordionItem.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                              </mat-icon>
                            </div>
                            
                            <div class="example-accordion-item-body" role="region" 
                                [style.display]="accordionItem.expanded ? '' : 'none'"
                                id="accordion-body-{{ i }}" [attr.aria-labelledby]="'accordion-header-' + i">
                              
                              <ul>
                                <li *ngFor="let topic of module?.Topics; let j = index">
                                  <span style="font-size: 20px; font-weight: 600;">Lección {{ j + 1 }}. {{ topic?.Title }}</span>
                                
                                  <div class="row mt-2">
                                    <div class="">
                                      <h5 style="font-size: 18px; font-weight: 600;">Recursos</h5>
                                      <span *ngIf="topic?.Resources.length === 0">No hay recursos para esta lección</span>
                                      <table class="table table-bordered" *ngIf="topic?.Resources.length > 0">
                                        <thead>
                                          <tr>
                                            <th class="text-center" style="width: 60%;">Nombre</th>
                                            <th class="text-center" style="width: 10%;">Formato</th>
                                            <th class="text-center" style="width: 10%;">Tamaño</th>
                                            <th class="text-center" style="width: 20%;">Acciones</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let resource of topic?.Resources">
                                            <td >{{ resource?.FileName }}</td>
                                            <td class="text-center">{{ resource?.Type }}</td>
                                            <td class="text-center">{{ formatFileSize(resource?.FileSize) }}</td>
                                            <td class="overflow-hidden">
                                              <div ngbDropdown container="body">
                                                <button type="button" class="btn btn-primary btn-sm" ngbDropdownToggle>Acciones</button>
                                                <div ngbDropdownMenu>
                                                  <a href="{{ resource?.Value }}" target="_blank" class="" ngbDropdownItem download>
                                                    <i class="fa fa-download"></i> Descargar
                                                  </a>
                                                  <a (click)="verArchivoMicrosoft(resource?.Value)" class="" ngbDropdownItem>
                                                    <i class="fa fa-eye"></i> Ver archivo
                                                  </a>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                
                                    <div class="">
                                      <h5 style="margin-top: 10px; font-size: 18px; font-weight: 600;">Video</h5>
                                      <table class="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th class="text-center" style="width: 60%;">Nombre</th>
                                            <th class="text-center" style="width: 10%;">Formato</th>
                                            <th class="text-center" style="width: 10%;">Tamaño</th>
                                            <th class="text-center" style="width: 20%;">Acciones</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngIf="!topic.vimeoUploading && topic.videoUrl">
                                            <td>{{ topic?.FileName || 'Video Lección ' + (j + 1) }}</td>
                                            <td class="text-center">{{ topic?.Type || 'Video' + (j + 1) }}</td>
                                            <td class="text-center">{{ topic?.FileSize || 'Desconocido' }}</td>
                                            <td class="overflow-hidden">
                                              <div ngbDropdown container="body">
                                                <button type="button" class="btn btn-primary btn-sm" ngbDropdownToggle>Acciones</button>
                                                <div ngbDropdownMenu>
                                                  <a href="{{ topic.videoUrl }}" *ngIf="topic.videoUrl" target="_blank" ngbDropdownItem>
                                                    <i class="fa fa-eye"></i> Ver video
                                                  </a>
                                                  <a href="{{topic?.videoUrl}}" *ngIf="topic.videoUrl.includes('learnify')" target="_blank" download ngbDropdownItem>
                                                    <i class="fa fa-download"></i> Descargar
                                                  </a>
                                                  <a href="{{topic?.videoUrl}}" *ngIf="topic.videoUrl.includes('vimeo')" target="_blank" ngbDropdownItem>
                                                    <i class="fa fa-eye"></i> Ver en Vimeo
                                                  </a>
                                                  <button *ngIf="topic.videoUrl.includes('learnify')" style="line-height: inherit;" 
                                                    [disabled]="topic.uploading" (click)="videoRemote.click()" ngbDropdownItem>
                                                    <i class="fa fa-upload"></i> {{ topic.uploading ? 'Cargando video' : 'Reemplazar' }}
                                                    <div *ngIf="topic.uploading" style="margin-left: 1rem;" class="spinner-border" role="status">
                                                      <span class="sr-only">Loading...</span>
                                                    </div>
                                                  </button>
                                                  <input type="file" style="display: none;" (change)="updateVideo($event, topic, i, j)" #videoRemote>
                                                  <button (click)="sharedToVimeo(topic)"  style="line-height: inherit;"
                                                    *ngIf="topic.videoUrl.includes('learnify')" ngbDropdownItem>
                                                    <i class="fa fa-cloud-upload"></i> {{ !topic.vimeoUploading ? 'Publicar en Vimeo' : 'Publicando...' }}
                                                    <div *ngIf="topic.vimeoUploading" style="margin-left: 1rem;" class="spinner-border" role="status">
                                                      <span class="sr-only">Loading...</span>
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>
                                              <!-- Barra de progreso si el video está subiendo -->
                                              <div *ngIf="topic.uploading" style="width: 100%; margin-top: 10px;">
                                                <ngb-progressbar [value]="topic.progress" [striped]="true" [animated]="true" [type]="getProgressBarType(topic.progress)" [showValue]="true" class="custom-progress">
                                                </ngb-progressbar>
                                                <div *ngIf="topic.progress === 100" style="margin-top: 5px; font-weight: bold; color: green;">
                                                    Video Subido
                                                </div>
                                              </div>                                            
                                            </td>
                                          </tr>                                          
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <hr>
                                </li>                                                             
                              </ul>
                            </div>
                          </cdk-accordion-item>
                        </ng-container>
                    </cdk-accordion>                      
                </div>
                <div class="d-flex justify-content-end" *ngIf="!productInfo.Active" style="margin-top: 10px;">
                    <button (click)="setActive()" class="btn btn-primary">Publicar en
                        Learnify</button>
                </div>   
            </div>
        </div>
    </div>
</div>