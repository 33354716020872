
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError } from 'rxjs';
import { ProductsService } from 'src/app/shared/service/product.service';
import { productDB } from 'src/app/shared/tables/product-list';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  //public product_list = []
  //this.product_list = productDB.product;

  public url: string = '';
  public search: string = '';
  public product_list: any[] = [];
  public filteredProductList: any[] = []; // Lista filtrada de productos
  public filteredCourses: any[] = [];
  public totalProducts: number;
  public showFilter = false;
  public productId: number;
  public deleteIsSuccess = false;
  public selectedStatus: string = ''; // Estado seleccionado
  active: boolean;
  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic'
  };

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private productService: ProductsService
  ) { }

  filterCoursesByStatus() {
    if (this.selectedStatus) {
        this.filteredCourses = this.product_list.filter(course => {
            if (this.selectedStatus === 'borrador') {
                // Mostrar solo los cursos que no están completos (CoursePct != '100.00')
                return course.CoursePct !== '100.00';
            } else if (this.selectedStatus === 'publicado') {
                // Mostrar solo los cursos activos (publicados) que están completos
                return course.CoursePct === '100.00' && course.Active;
            } else if (this.selectedStatus === 'revision') {
                // Mostrar solo los cursos en revisión (no activos) que están completos
                return course.CoursePct === '100.00' && !course.Active;
            }
        });
    } else {
        // Si no hay filtro, mostrar todos los cursos
        this.filteredCourses = this.product_list;
    }
  }


  ngOnInit() {
    const storedSearch = localStorage.getItem('searchValue');
    if (storedSearch) {
      this.search = storedSearch;
      this.showFilter = true;
    }
    this.loadData();
  }
  updateSearchValue(newValue: string) {
    this.search = newValue;
    if (this.search.trim()) {
      localStorage.setItem('searchValue', this.search);
    }
    this.loadData();
  }
  clearSearch() {
    this.search = '';
    this.selectedStatus = '';  // Limpiar el estado seleccionado
    localStorage.removeItem('searchValue');
    this.loadData();  // Recargar los datos después de limpiar los filtros
  }
  toggleFilter() {
    this.showFilter = !this.showFilter;
  }
  setModalContent(content: any, productId: number, status: boolean): void {
    this.productId = productId;
    this.active = status;
    this.modalService.open(content, this.modalConfig);
  }

  loadData() {
    // const url = this.router.url.split("/").pop();
    // if (url == 'courses') {

    //   const user = JSON.parse(localStorage['currentUser']);

    //   this.productService
    //     .getCourses2(this.search, user.id)
    //     .subscribe(data => {
    //       this.product_list = data;
    //       this.totalProducts = this.product_list.length;
    //       this.filterByStatus();  // Filtrar los cursos por estado
    //     })
    // } else {
    //   this.productService
    //     .getCapacitations()
    //     .subscribe(data => {
    //       this.product_list = data;
    //       this.totalProducts = this.product_list.length;
    //     })
    // }

    const user = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.productService.getCourses2(this.search, user.id).subscribe(data => {
        this.product_list = data;
        this.filteredCourses = this.product_list; // Inicialmente mostrar todos los cursos
        this.totalProducts = this.product_list.length;
        console.log('info de los cursos:', this.product_list);
    });
  }

  
//   // Método para filtrar los cursos según el estado seleccionado
//   filterByStatus() {
//     this.product_list.forEach(course => {
//       if (this.selectedStatus === 'publicado' && course.Active) {
//         course.status = 'Publicado';
//       } else if (this.selectedStatus === 'revision' && !course.Active) {
//         course.status = 'Revisión';
//       } else {
//         // Restaurar el estado por defecto si no coincide con la selección
//         course.status = course.Active ? 'Publicado' : 'Revisión';
//       }
//     });
// }
// }


// Método para filtrar los cursos según el estado seleccionado
// filterByStatus() {
//   if (this.selectedStatus === '') {
//     // Si no hay estado seleccionado, mostrar todos los cursos
//     this.filteredProductList = this.product_list;
//   } else {
//     // Filtrar la lista de cursos según el estado seleccionado
//     this.filteredProductList = this.product_list.filter(course => {
//       if (this.selectedStatus === 'publicado') {
//         return course.Active; // Mostrar solo los cursos activos
//       } else if (this.selectedStatus === 'revision') {
//         return !course.Active; // Mostrar solo los cursos inactivos
//       }
//       return true;
//     });
//   }
// }

openAddCourseModal(content: any) {
  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
}
}
