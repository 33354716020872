import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { OrderDB, ORDERDB } from 'src/app/shared/tables/order-list';
import { ProductsService } from 'src/app/shared/service/product.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';



@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [TableService, DecimalPipe],
})

export class OrdersComponent implements OnInit {
  orders: any[] = [];
  filteredOrders: any[] = [];
  status: string = 'Abierto';
  selectedStatus: string = '';
  limit: number = 10;
  offset: number = 0;
  totalOrders: number = 0;
  page: number = 1;
  totalSales: number = 0;
  search: string = '';
  selectedDate: string = '';
  startDate: string = ''; 
  endDate: string = ''; 
  isCustomRange: boolean = false;
  selectedRange: number = 0; 


  private dateSubject = new Subject<void>();
  constructor(private productsService: ProductsService) { }

  ngOnInit(): void {
    const storedSearch = localStorage.getItem('searchValue');
    const storedStatus = localStorage.getItem('selectedStatus');

    if (storedSearch) {
      this.search = storedSearch;
    }
    
    if (storedStatus) {
      this.selectedStatus = storedStatus;
    }
    // Configurar el debouncing para la filtración de fechas
    this.dateSubject.pipe(debounceTime(300)).subscribe(() => {
      this.filterByDate(); // Llama a la función de filtrado cuando se emitió el cambio
    });
    this.loadOrders(this.status, this.limit, this.offset);
  }

  // Método para cargar órdenes por estado, límite y offset
  loadOrders(status: string, limit: number, offset: number): void {
    this.productsService.getOrder(status, limit, offset).subscribe(
      (data) => {
        this.orders = data.list;
        this.totalOrders = data.total;
        this.filterOrders();
        console.log('Órdenes cargadas:', this.orders);

        if (this.orders.length === 0 && this.page > 1) {
          this.page--;
          this.offset = (this.page - 1) * this.limit;
          this.loadOrders(this.status, this.limit, this.offset);
        }
        // console.log('Órdenes cargadas:', this.orders);

      },
      (error) => {
        console.error('Error al cargar las órdenes:', error);
      }
    );
  }

  // Filtrar las órdenes basadas en la búsqueda
  filterOrders(): void {
    // Filtrar órdenes por nombre del cliente
    this.filteredOrders = this.orders.filter(order =>
      `${order.Customer.firstName} ${order.Customer.lastName}`
        .toLowerCase()
        .includes(this.search.toLowerCase())
    );

    // Calcular el total de ventas
    this.totalSales = this.filteredOrders.reduce((acc, order) => acc + order.TotalAmount, 0);
    //  console.log('Total de ventas:', this.totalSales); 
  }
   // Método para filtrar órdenes basadas en el rango de fecha
   filterByDate(): void {
    let start: Date;
    let end: Date;

    if (this.isCustomRange) {
      // Si es un rango personalizado, tomamos las fechas seleccionadas
      start = new Date(this.startDate);
      end = new Date(this.endDate);
    } else {
      // Si no es personalizado, tomamos el rango predefinido
      end = new Date(); // Fecha de hoy
      start = new Date(); // Inicializamos la fecha de inicio
      start.setDate(end.getDate() - this.selectedRange); // Restamos los días seleccionados
    }

    this.filteredOrders = this.orders.filter(order => {
      const orderDate = new Date(order.SaleOrderDate);
      return orderDate >= start && orderDate <= end;
    });

    // Actualizar el total de ventas después de aplicar el filtro
    this.totalSales = this.filteredOrders.reduce((acc, order) => acc + order.TotalAmount, 0);
  }
  // Manejar el cambio de búsqueda
  updateSearchValue(newValue: string): void {
    this.search = newValue; 
    localStorage.setItem('searchValue', this.search);
    if (this.search.trim() === '') {
      this.filteredOrders = this.orders; 
    } else {
      this.filterOrders(); 
    }
  }

  // Manejar el cambio de estado desde el filtro
  onStatusChange(status: string): void {
    this.selectedStatus = status;
    this.page = 1;
    this.offset = 0;
    localStorage.setItem('selectedStatus', this.selectedStatus);
    this.loadOrders(this.selectedStatus, this.limit, this.offset);
  }
  // Manejar el cambio de rango de fecha
  onDateRangeChange(selectedValue: string): void {
    if (selectedValue === 'custom') {
      // Mostrar campos para rango personalizado
      this.isCustomRange = true;
    } else {
      // Aplicar el rango predefinido
      this.isCustomRange = false;
      this.selectedRange = parseInt(selectedValue, 10); // Guardar el rango seleccionado
      this.filterByDate(); // Filtrar las órdenes
    }
  }
  // Manejar el cambio de fechas en el rango personalizado
  onDateChange(): void {
    if (this.startDate && this.endDate) {
      this.dateSubject.next(); // Emitir un valor al sujeto solo cuando ambas fechas están seleccionadas
    }
  }
   // Cambiar de página
   onPageChange(page: number): void {
    if (page < 1 || page * this.limit > this.totalOrders) return;
    this.page = page;
    this.offset = (this.page - 1)* this.limit;
    this.loadOrders(this.selectedStatus, this.limit, this.offset);
  }
}




//   public closeResult: string;
//   public tableItem$: Observable<OrderDB[]>;
//   public searchText;
//   total$: Observable<number>;

//   constructor(public service: TableService, private modalService: NgbModal) {
//     this.tableItem$ = service.tableItem$;
//     this.total$ = service.total$;
//     this.service.setUserData(ORDERDB)
//   }

//   @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

//   onSort({ column, direction }: SortEvent) {
//     // resetting other headers
//     this.headers.forEach((header) => {
//       if (header.sortable !== column) {
//         header.direction = '';
//       }
//     });

//     this.service.sortColumn = column;
//     this.service.sortDirection = direction;

//   }

//   open(content) {
//     this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
//       this.closeResult = `Closed with: ${result}`;
//     }, (reason) => {
//       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
//     });
//   }

//   private getDismissReason(reason: any): string {
//     if (reason === ModalDismissReasons.ESC) {
//       return 'by pressing ESC';
//     } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
//       return 'by clicking on a backdrop';
//     } else {
//       return `with: ${reason}`;
//     }
//   }

//   ngOnInit() {
//   }

// }
