<div class="container-fluid">
    <div class="row product-adding">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header">
                    <h5>Nuevo curso</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="courseForm" class="needs-validation">
                        <div ngbAccordion>
                            <div ngbAccordionItem [collapsed]="step != 0">
                                <h2 ngbAccordionHeader>
                                    <button ngbAccordionButton>Datos generales</button>
                                </h2>
                                <div ngbAccordionCollapse>
                                    <div ngbAccordionBody>
                                        <ng-template>
                                            <div class="row">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="col-form-label">Título: <span>*</span></label>
                                                        <input type="text" class="form-control"
                                                            (keyup)="generateSlug($event)"
                                                            [ngClass]="{'error' : submitted && courseForm.controls.Title.hasError('required') }"
                                                            [formControlName]="'Title'" id="title" placeholder="Titulo">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="col-form-label">Slug: <span>*</span></label>
                                                        <input type="text" class="form-control" disabled
                                                            [ngClass]="{'error' : submitted && courseForm.controls.Title.hasError('required') }"
                                                            [(ngModel)]="slug" [ngModelOptions]="{standalone: true}"
                                                            placeholder="Slug">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="col-form-label">SKU: <span>*</span></label>
                                                        <input type="text" class="form-control"
                                                            [formControlName]="'SKU'" id="title"
                                                            [ngClass]="{'error' : submitted && courseForm.controls.SKU.hasError('required') }"
                                                            placeholder="SKU">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="col-form-label">Categoría: <span>*</span></label>
                                                        <select formControlName="category_id" name="category"
                                                            class="custom-select form-select" required="">
                                                            <option value="0" selected disabled>Selecciona</option>
                                                            <option *ngFor="let cat of subcategoryList"
                                                                [value]="cat.id">{{
                                                                cat.ValueAlpha }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="form-group">
                                                        <label class="col-form-label">Precio: <span>*</span></label>
                                                        <input type="text" class="form-control"
                                                            [formControlName]="'CourseModel'" id="title"
                                                            placeholder="Precio">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="form-group">
                                                        <label class="col-form-label">Precio descuento:</label>
                                                        <input type="text" class="form-control"
                                                            [formControlName]="'DiscountPrice'" id="title"
                                                            placeholder="Precio descuento">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="form-group">
                                                        <label class="col-form-label">Moneda: </label>
                                                        <select formControlName="CurrencyCode" name="category"
                                                            class="custom-select form-select" required="">
                                                            <option value="MXN">MXN</option>
                                                            <option value="USD">USD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mb-0">
                                                <div class="product-buttons text-end mt-3">
                                                    <!-- <button type="button" class="btn btn-light">Discard</button> -->
                                                    <button type="button" (click)="step = 1;"
                                                        class="btn btn-primary">Siguiente</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div ngbAccordionItem [collapsed]="step != 1">
                                <h2 ngbAccordionHeader>
                                    <button ngbAccordionButton>
                                        <span>Información del curso</span>
                                    </button>
                                </h2>
                                <div ngbAccordionCollapse>
                                    <div ngbAccordionBody>
                                        <ng-template>
                                            <div class="form-group">
                                                <label class="col-form-label"> Banner del curso:</label>
                                                <div class="card-body dropzone-custom p-0">
                                                    <ngx-dropzone class="dropzone-border" (change)="onSelect($event)">
                                                        <ngx-dropzone-label>
                                                            <div class="dz-message needsclick">
                                                                <i class="fa fa-cloud-upload"></i>
                                                                <h4 class="mb-0 f-w-600">Arrastra tu archivo o da click
                                                                    para buscarlo.
                                                                </h4>
                                                            </div>
                                                        </ngx-dropzone-label>
                                                        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                                            (removed)="onRemove(f)">
                                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type
                                                                }})</ngx-dropzone-label>
                                                        </ngx-dropzone-preview>
                                                    </ngx-dropzone>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">Nivel: <span>*</span></label>
                                                <select [formControlName]="'level_id'" name="level"
                                                    class="custom-select form-select" required="">
                                                    <option value="0" selected disabled>Selecciona</option>
                                                    <option *ngFor="let lvl of levelList" [value]="lvl.id">{{
                                                        lvl.ValueAlpha }}</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="validationCustom01" class="col-form-label pt-0">Duración:
                                                    <span>*</span></label>
                                                <input class="form-control" id="validationCustom01" type="text"
                                                    formControlName="TotalHours" name="hours" required="">
                                            </div>
                                            <div class="form-group needs-validation my-2">
                                                <label class="col-form-label">¿Para quién va dirigido el curso?:</label>
                                                <div class="form-group mb-0">
                                                    <div class="description-sm">
                                                        <ckeditor [editor]="Editor" formControlName="TargetMarket"
                                                            data="<p>Hello, world!</p>">
                                                        </ckeditor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group needs-validation my-2">
                                                <label class="col-form-label">¿Qué se aprenderá en este curso?:</label>
                                                <div class="form-group mb-0">
                                                    <div class="description-sm">
                                                        <ckeditor [editor]="Editor" formControlName="Objective"
                                                            data="<p>Hello, world!</p>">
                                                        </ckeditor>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mb-0">
                                                <div class="product-buttons text-between mt-3">
                                                    <button type="button" (click)="step = 0;"
                                                        class="btn btn-light">Anterior</button>
                                                    <button type="button" (click)="step = 2;"
                                                        class="btn btn-primary">Cargar módulos</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-xl-6" *ngIf="step === 2">
            <div class="card">
                <div class="card-header">
                    <h5>Meta Data</h5>
                </div>
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="validationCustom05" class="col-form-label pt-0"> Título</label>
                                    <input class="form-control" id="validationCustom05" type="text" required="">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom05" class="col-form-label pt-0"> Número de temas</label>
                                    <input class="form-control" id="validationCustom05" type="text" required="">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom05" class="col-form-label pt-0"> Porcentaje</label>
                                    <input class="form-control" id="validationCustom05" type="text" required="">
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom05" class="col-form-label pt-0"> Duración</label>
                                    <input class="form-control" id="validationCustom05" type="text" required="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="col-form-label"> Cargar video:</label>
                                    <div class="card-body dropzone-custom p-0">
                                        <ngx-dropzone class="dropzone-border" (change)="onSelect($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick">
                                                    <i class="fa fa-cloud-upload"></i>
                                                    <h4 class="mb-0 f-w-600">Arrastra tu archivo o da click
                                                        para buscarlo.
                                                    </h4>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                                (removed)="onRemove(f)">
                                                <ngx-dropzone-label>{{ f.name }} ({{ f.type
                                                    }})</ngx-dropzone-label>
                                            </ngx-dropzone-preview>
                                        </ngx-dropzone>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>