import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Course } from 'src/app/shared/interfaces/data.interface';
import { ProductsService } from 'src/app/shared/service/product.service';

@Component({
  selector: 'app-digital-add',
  templateUrl: './digital-add.component.html',
  styleUrls: ['./digital-add.component.scss']
})


export class DigitalAddComponent implements OnInit {

  public Editor = ClassicEditor;
  public course: Course = {};
  slug: string = '';
  submitted = false;
  courseForm: FormGroup;
  subcategoryList: any;
  levelList: any;
  step = 0;

  constructor(
    private productService: ProductsService,
    private fb: FormBuilder,
    private router: Router,
  ) {


  }

  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnInit() {
    this.loadData();
    this.createForm();
  }

  createForm() {
    //const user = JSON.parse(localStorage[''])
    this.courseForm = this.fb.group({
      AccessType: [''],
      teacher_id: [''],
      AditionalName: [''],
      AditionalPrice: [''],
      CourseModel: [''],
      CurrencyCode: ['MXN'],
      Description: ['', [Validators.required]],
      dateString: [''],
      Introduction: ['', Validators.required],
      Language: [''],
      LanguageSubtitles: [''],
      Level: [''],
      Objective: ['', Validators.required],
      SKU: ['', Validators.required],
      SubCategory: [''],
      Title: ['', Validators.required],
      TotalHours: ['', [Validators.required, Validators.min(0)]],
      UnitPrice: ['', [Validators.required, Validators.min(0)]],
      DiscountPrice: [''],
      category_id: [''],
      level_id: [''],
      TargetMarket: [''],
      UnitMeasureCode: ['']
    });
  }

  async loadData() {
    this.productService.getCategories().subscribe(data => {
      this.subcategoryList = data;
    });
    this.productService.getCatalogsName("Level").subscribe(data => {
      this.levelList = data;
    });
  }

  generateSlug(event) {
    const value = event.target.value;
    const newString = value.toString().toLowerCase().replace(/[^A-Z0-9]/ig, "-");
    console.log(newString);
    this.slug = newString;
  }



}
