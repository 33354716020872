import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
//import { CourseModel, FeatureModel, QuizModel } from '../../components/products/product.models';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../utils/headers.functions';
import { MatDialog } from '@angular/material/dialog';
import { LeadsModule } from 'src/app/components/eduman/leads/leads.module';


const BASE_URL: string = environment.API_URL;
@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    sharedVideo(body: { name: any; path: any; }) {
        return this.http.post<any>(`${BASE_URL}/upload-vimeo`, body);
    }
    uploadVideoModulo(formData: FormData): Observable<any> {
        const httpOptions = {
            reportProgress: true,  // Para obtener el progreso de la subida
            observe: 'events' as const, // Aseguramos que observe es de tipo 'events'
            responseType: 'json' as const  // Para asegurarnos de que la respuesta sea JSON
        };

        return this.http.put<any>(`${BASE_URL}/upload-video`, formData, {
            reportProgress: httpOptions.reportProgress,
            observe: httpOptions.observe,
            responseType: httpOptions.responseType
        });
    }

    updateTopic(id: any, newtopic: { videoUrl: any; }) {
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/altertopics/${id}`, newtopic, { headers });
    }

    constructor(
        private http: HttpClient,
        private router: Router

    ) {
    }

    

    public uploadImg(formData: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/uploadimg`, formData);
    }

    public uploadFile2(formData: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/uploadfile`, formData);
    }

    setCoursePreview(uuid: string, id: number) {
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/masteracc/${uuid}`, { productid: id }, { headers });
    }

    validateActivation(productId: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/validate-products/${productId}`, { headers });
    }

    public uploadFile(formData: any, productId: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/saveDocument/${productId}`, formData, { headers });
    }

    public uploadFileWithoutID(formData: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/uploadarchive`, formData, { headers });
    }

    public createFeature(feature: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/catalogs`, feature, { headers });
    }

    public updateFeature(id: number, feature: any): Observable<any> {
        const buildFeature = this.editFeature(feature);
        const updatedFeature = this.addBackendStructureFeature(buildFeature);
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/catalog/${id}`, updatedFeature, { headers });
    }

    public getFeatures(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Feature'}`, { headers });
    }

    public deleteFeature(id: number): Observable<any> {
        return this.http.delete<any>(`${BASE_URL}/catalog/${id}`);
    }

    public getCatalogs(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs`, { headers });
    }

    public getCatalogsName(name): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${name}`, { headers });
    }

    public postCatalogs(body): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/catalogs/`, body, { headers });
    }

    public getValuestoCatalog(Catalog: string): Observable<any> {
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${Catalog}`);
    }

    public getValue(id: string | number): Observable<any> {
        return this.http.get<any>(`${BASE_URL}/catalog/${id}`);
    }

    public getCatalogsAlias(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-alias`, { headers });
    }

    public getSpecifications(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Specification'}`, { headers });
    }

    public getFamilies(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Family'}`, { headers });
    }

    public getCategories(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Category'}`, { headers });
    }

    public getSubCategories(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Subcategory'}`, { headers });
    }

    public getCourses(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=${'Course'}`);
    }

    public getCourses2(search: string, id: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=Course&search=${search}&user_id=${id}`, { headers });
    }

    public getCapacitations(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=${'Capacitation'}`);
    }

    public getWebinars(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=${'Webinar'}`);
    }
    public getEvents(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=${'Event'}`);
    }

    public getProduct(productId: string): Observable<any> {
        return this.http.get<any>(`${BASE_URL}/products/${productId}`);
    }

    public getProductsFiltered(family: string): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products-filter?family=${family}`);
    }

    public createCourse(product: any): Observable<any> {
        const user = JSON.parse(localStorage['currentUser']);
        const headers = new HttpHeaders().set('Accept', 'application/json').set("Authorization", user.token);
        return this.http.post<any>(`${BASE_URL}/products`, product, { headers });
    }

    public updateCourse(id: number, product: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/products/${id}`, product, { headers });
    }

    public updateCourse2(id: number, product: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/products`, product, { headers });
    }

    public deleteProduct(id: number): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.delete<Observable<any>>(`${BASE_URL}/products/${id}`, { headers });
    }

    public mapRequiredValues(product: any): any {
        //console.log(product);
        const productInfo: any = {};

        {
            productInfo.id = product.id;
            productInfo.Category = product.Category;
            productInfo.SKU = product.SKU;
            productInfo.Title = product.Title;
            productInfo.Description = product.Description;
            productInfo.UnitPrice = product.UnitPrice;
            productInfo.CurrencyCode = product.CurrencyCode;
            productInfo.ImgFileUrl = product.ImgFileUrl;
            productInfo.CourseModel = product.CourseModel;
            productInfo.Objective = product.Objective;
            productInfo.Introduction = product.Introduction;
            productInfo.Level = product.Level;
            productInfo.TotalHours = product.TotalHours;
            productInfo.Language = product.Language;
            productInfo.LanguageSubtitles = product.LanguageSubtitles;
            productInfo.AccessType = product.AccessType;
            productInfo.TargetMarket = product.TargetMarket;
            productInfo.FileUrl = product.FileUrl;
            productInfo.DiscountPrice = product.DiscountPrice;
            productInfo.StartDate = product.StartDate;
            productInfo.EndDate = product.EndDate;
            productInfo.Teacher = product.Teacher;
            productInfo.Percentage = product.Percentage;
            productInfo.Name = product.Name;
            productInfo.numberTopics = product.numberTopics;
            productInfo.courseDuration = product.courseDuration;
            productInfo.SubCategory = product.SubCategory;
            productInfo.teacher_name = product.teacher_name;
        }

        return productInfo;
    }

    private editProduct(product: any): any {
        // console.log(product);
        const editProduct = {
            Title: product.Title,
            Family: product.Family,
            Category: product.Category,
            SubCategory: product.SubCategory,
            Brand: product.Brand,
            SKU: product.SKU,
            Model: product.Model,
            Description: product.Description,
            UnitPrice: product.UnitPrice,
            DiscountPrice: product.DiscountPrice,
            CurrencyCode: product.CurrencyCode,
        };
        return editProduct;
    }

    private addBackendStructure(product: any) {
        return {
            Title: product.Title,
            Family: product.Family,
            Category: product.Category,
            SubCategory: product.SubCategory,
            Brand: product.Brand,
            SKU: product.SKU,
            Model: product.Model,
            Description: product.Description,
            UnitPrice: product.UnitPrice,
            DiscountPrice: product.DiscountPrice,
            CurrencyCode: product.CurrencyCode,
            ProductInfo: product.ProductInfo
        };
    }

    private editFeature(feature: any): any {
        const editFeature = {
            CatalogName: feature.CatalogName,
            ValueAlpha: feature.ValueAlpha,
        };
        return editFeature;
    }

    private addBackendStructureFeature(feature: any) {
        return {
            CatalogName: feature.CatalogName,
            ValueAlpha: feature.ValueAlpha
        };

    }


    //metodos para enviar datos de usuario
    updateProfile(body: any) {
        const headers = getHeaders(); // Obtén los encabezados necesarios
        const url = 'https://api-qa.learnify.com.mx/api/user-profile/493'; // URL correcta con el ID del perfil
        //return this.http.put<any>(`${BASE_URL}/user-profile`, body, { headers }); // Realiza la solicitud PUT con la URL, cuerpo y encabezados
        return this.http.put(url, body, { headers }); // Realiza la solicitud PUT con la URL completa, el cuerpo y los encabezados
    }
    
    // Método para obtener leads con paginación, búsqueda y filtro por estado
    public obtenerLeads(userId: number, limit: number, offset: number, status: string, search: string): Observable<LeadsModule> {
        const headers: HttpHeaders = getHeaders();
        
        // Construir los parámetros de la URL dinámicamente
        let params = `?limit=${limit}&offset=${offset}`;
        
        if (status) {
            params += `&status=${status}`;
        }
        
        if (search) {
            params += `&search=${search}`;
        }
        
        return this.http.get<LeadsModule[]>(`${BASE_URL}/leads/${userId}${params}`, { headers });
    }

    // Método para obtener Lead por Id
    public getProspecto(id: number): Observable<any> {
    // Método para obtener leads
    //public obtenerLeads(userId: number): Observable<LeadsModule[]> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/update-lead/${id}`, { headers });
    }

    // Método para subir Lead
    public postProspecto(id: number,body: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/update-lead/${id}`, body, { headers });
    }


    //Método para obtener status de Leads 

    public ActualizarStatus(id: number, body: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/update-lead/${id}`, body, { headers });
    }

    public enviarloanding(body): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/product-info/`, body, { headers });
    }

    // Método para obtener las lista de órdenes 
    getOrder(status: string, limit: number, offset: number): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/sales?status=${status}&limit=${limit}&offset=${offset}`, { headers });
    }

    // Método para obtener la lista de emails únicos
    getEmails(id: number, limit: number, offset: number, status: string, search: string): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/v2/leads/${id}?limit=${limit}&offset=${offset}&status=${status}&search=${search}`, { headers });
    }
    public postEmail(body:any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/eventEmail`, body, { headers });
    }

    
}

