<div class="row mb-4">
    <p class="mb-1 total" style="text-align: right; margin-top: -20px; padding-right: 12px;">Mis cursos
        <strong>{{totalProducts}}</strong>
    </p>
    <div class="col-xs-12 col-sm-12">
        <div class="action d-flex justify-content-end align-items-center">
            <div class="d-flex align-items-center" style="flex: 1; margin-right: 10px;">
                <div style="flex: 0.9; margin-right: 10px;">
                    <label class="sr-only">Buscar por nombre o SKU</label>
                    <div class="input-group" style="width: 100%;">
                        <input type="text" class="form-control" style="width: 100%;" [(ngModel)]="search"
                            (ngModelChange)="updateSearchValue($event)" [ngModelOptions]="{standalone: true}"
                            (keyup)="loadData()" placeholder="Buscar por nombre o SKU">
                    </div>
                </div>
                <!-- <select class="form-control" style="flex: 0.2; margin-right: 10px;" [(ngModel)]="selectedStatus" (change)="filterByStatus()"> -->
                <select class="form-control" style="flex: 0.2; margin-right: 10px;" [(ngModel)]="selectedStatus"
                    (change)="filterCoursesByStatus()">

                    <option value="">Seleccione status</option>
                    <option value="borrador">Borrador</option>
                    <option value="publicado">Publicado</option>
                    <option value="revision">Revisión</option>
                </select>
                <button *ngIf="search || selectedStatus" class="btn btn-danger"
                    style="white-space: nowrap; margin-right: 10px;" (click)="clearSearch()">
                    Borrar Filtro
                </button>
            </div>
            <div class="d-flex">
                <a class="btn btn-primary btn-sm" style="white-space: nowrap;" (click)="openAddCourseModal(modal)">
                    <span>Agregar</span>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="table-template">
    <table class=" table table-lg table-hover row-border hover">
        <thead>
            <tr>
                <!-- <th>Imagen</th> -->
                <th style="width: 15%;">Título</th>
                <th style="width: 15%;">Instructor</th>
                <th style="width: 10%;">Precio</th>
                <th style="width: 15%;">Categoría</th>
                <th style="width: 10%;">Nivel</th>
                <th style="width: 10%;">Status</th> <!-- Nueva columna -->
                <!-- <th style="width: 10%;">Total de horas</th> -->
                <th style="width: 10%;">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <!-- <tr *ngFor="let course of product_list"> -->
            <!-- <td><img width="80px" height="auto" [src]="course.ImgFileUrl"></td> quite img-->
            <!-- <tr *ngFor="let course of filteredProductList"> -->
            <tr *ngFor="let course of filteredCourses">
                <td>{{course.Title}}</td>
                <td>{{course.Instructor}}</td>
                <td>{{course.UnitPrice| currency:'MXN':'symbol-narrow':'1.2-2' }}</td>
                <td>{{course?.Category?.ValueAlpha}}</td>
                <td>{{course?.Level?.ValueAlpha}}</td>
                <td>
                    <button disabled class="btn" [ngClass]="{
                            'btn-primary': course.CoursePct === '100.00' && course.Active,
                            'btn-secondary': course.CoursePct === '100.00' && !course.Active,
                            'btn-warning': course.CoursePct !== '100.00'
                        }">
                        {{
                        course.CoursePct !== '100.00'
                        ? 'Borrador'
                        : course.Active
                        ? 'Publicado'
                        : 'Revisión'
                        }}
                    </button>
                </td>

                <!-- <td>{{course.status ? 'Publicado' : 'Revisión'}}</td> Estado del curso -->
                <!-- <td>{{course.TotalHours}}</td> -->
                <td class="actions-cell">

                    <a [routerLink]="['/products/product-detail', course.id]" mat-icon-button class="mx-3">
                        <mat-icon>remove_red_eye</mat-icon>
                    </a>
                    <a [routerLink]="['edit', course.id]" mat-icon-button class="mx-3"
                        aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon>edit</mat-icon>
                    </a>


                    <a [routerLink]="['/products/product-landing', course.id]" mat-icon-button class="mx-3"
                        aria-label="Example icon button with a vertical three dot icon">
                        <mat-icon>ballot</mat-icon>
                    </a>
                    <!-- <div class="opciones">/products/courses
                        Opciones <i class="fa fa-caret-down ml-1" aria-hidden="true"></i> <br>

                        <ul>
                            <li [routerLink]="['show', course.id]">Ver curso</li>
                            <li [routerLink]="['edit', course.id]">Editar curso</li>
                            <li [routerLink]="['view-programs', course.id]">Agregar y/o ver módulos</li>
                            <li>{{course.Active === true ?
                                'Ocultar' : 'Publicar'}}</li>
                            <li>Previsualizar curso</li>
                        </ul>
                    </div> -->
                </td>
            </tr>
        </tbody>
    </table>

    <!-- Modal definition (add this at the bottom of your template) -->
    <ng-template #modal let-modal class="modal-dialog">
        <div class="text-center">
            <div class="modal-header justify-content-end"> <!-- Alinea el botón de cerrar a la derecha -->
                <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
            </div>
            <h5 class="modal-title">¿Deseas asesoría para estructurar tu curso?</h5>
            <div class="modal-footer">
                <a class="btn btn-primary btn-lg" (click)="modal.close()" [routerLink]="['add-course']">
                    Sí, quiero asesoría
                </a>
                <a class="btn btn-secondary btn-lg" (click)="modal.close()" [routerLink]="['add-course']"
                    [queryParams]="{ step: 3 }">
                    No, continuar sin asesoría
                </a>
            </div>
        </div>
    </ng-template>

</div>