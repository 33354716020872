import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './physical/category/category.component';
import { SubCategoryComponent } from './physical/sub-category/sub-category.component';
import { ProductListComponent } from './physical/product-list/product-list.component';
import { AddProductComponent } from './physical/add-product/add-product.component';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { ProductDetailComponent } from './physical/product-detail/product-detail.component';
import { AddCourseComponent } from './courses/add-course/add-course.component';
import { NewProductComponent } from './physical/new-product/new-product.component';
import { LoadingComponent } from '../loading/loading.component';
import { LandingComponent } from '../loading/landing/landing.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'physical/category',
        component: CategoryComponent,
        data: {
          title: "Category",
          breadcrumb: "Category"
        }
      },
      {
        path: 'courses/add-course',
        component: AddCourseComponent,
        data: {
          title: "Cursos",
          breadcrumb: "Crear curso"
        }
      },

      {
        path: 'courses/edit/:id',
        component: AddCourseComponent,
        data: {
          title: "Cursos",
          breadcrumb: "Editar curso"
        }
      },
      {
        path: 'product-landing/:id',
        component: LoadingComponent,
        data: {
          title: "landing",
          breadcrumb: "Crear curso"
        }
      },
      {
        path: 'landing1/:Slug',
        component: LandingComponent,
        data: {
          title: "landing1",
          breadcrumb: "Landing 1"
        }
      },
      {
        path: 'courses/new-course',
        component: NewProductComponent,
        data: {
          title: "Cursos",
          breadcrumb: "Crear curso"
        }
      },
      {
        path: 'courses',
        component: ProductListComponent,
        data: {
          title: "Cursos",
          breadcrumb: "Cursos"
        }
      },
      {
        path: 'physical/sub-category',
        component: SubCategoryComponent,
        data: {
          title: "Sub Category",
          breadcrumb: "Sub Category"
        }
      },
      {
        path: 'physical/product-list',
        component: ProductListComponent,
        data: {
          title: "Product List",
          breadcrumb: "Product List"
        }
      },
      {
        path: 'product-detail/:id',
        component: ProductDetailComponent,
        data: {
          title: "Detalles del producto",
          breadcrumb: "Detalles del producto"
        }
      },
      {
        path: 'physical/add-product',
        component: AddProductComponent,
        data: {
          title: "Add Products",
          breadcrumb: "Add Product"
        }
      },
      {
        path: 'digital/digital-category',
        component: DigitalCategoryComponent,
        data: {
          title: "Category",
          breadcrumb: "Category"
        }
      },
      {
        path: 'digital/digital-sub-category',
        component: DigitalSubCategoryComponent,
        data: {
          title: "Sub Category",
          breadcrumb: "Sub Category"
        }
      },
      {
        path: 'digital/digital-product-list',
        component: DigitalListComponent,
        data: {
          title: "Product List",
          breadcrumb: "Product List"
        }
      },
      {
        path: 'digital/digital-add-product',
        component: DigitalAddComponent,
        data: {
          title: "Add Products",
          breadcrumb: "Add Product"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
