<div class="container" style="margin-top: 6rem;">
    <div class="row">
        <div class="col-md-8 col-sm-12" style="text-align: center;"> 
            <h2>{{datosCurso.micronicho}}</h2>
            <h1>{{datosCurso.deseo}}</h1>
            
        
            <form [formGroup]="form"  (ngSubmit)="onSubmit()">
                <!-- <div style="display: flex; justify-content: space-around;"> -->
                    <div>
                        <mat-form-field appearance="outline" style="width: 60%;">
                            <mat-label>Nombre</mat-label>
                            <input  matInput formControlName="name" placeholder="Ingresa tu nombre">
                            <mat-error *ngIf="form.get('name')">
                              El nombre es requerido
                            </mat-error>
                        </mat-form-field>
                        
                    </div>
                    <div>
                        <mat-form-field appearance="outline" style="width: 60%;">
                          <mat-label>Correo</mat-label>
                          <input matInput formControlName="email" placeholder="Ingresa tu Correo">
                          <mat-error *ngIf="form.get('email')">
                            El correo es requerido
                          </mat-error>
                        </mat-form-field>
                    </div>
                <!-- </div>   -->
                <div class="form-group">
                    <button  [disabled]="form.invalid" type="submit" style="font-size: 20px; margin-bottom: 2rem;" class="edu-btnTransition" >Enviar</button>
                </div>
          
                  
            </form>
        </div>
        <div class="col-md-4 col-sm-12">
            <img [src]="userData.imagen" alt="" class="img-fluid" style="border-radius: 7px;">
        </div>
    </div>

    
</div>

<div class="container-fluid" style="color: white; background-image: linear-gradient(to right, #584294, #D96C70); padding: 4rem; text-align: center; margin-bottom: 1rem;">
    <div class="row">
        <div class="col-12 col-md-6">
            <h3 style="color: white;">{{datosCurso.micronicho}}</h3>
        </div>
        <div class="col-12 col-md-6">
            <h3 style="color: white;">{{datosCurso.problema}}</h3>
        </div>
    </div>
</div>

<!-- footer-area-start -->
