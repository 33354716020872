import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../utils/headers.functions';
import * as moment from 'moment';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(private http: HttpClient) {
    
  }

  public getTotals(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/dashboard`, {headers});
  }

  public getTopCourses(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/topcourses`, {headers});
  }

  getCoursesImparted(idRol: string) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/teacherCourses/${idRol}`, {headers});
  }

  getStudentsRegistered(id: any) {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/getStudents/${id}`, {headers});
  }

  public getTopMembers(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/topmembers`, {headers});
  }

  public getCoursesGraph(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    return this.http.get<any>(`${BASE_URL}/coursesgraph`, {headers});
  }

  public getDashDates(): Observable<any> {
    const headers: HttpHeaders = getHeaders();
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endDate   = moment().endOf('month').format('YYYY-MM-DD');
    return this.http.get<any>(`${BASE_URL}/dashboarddates?StartDate=${startDate}&EndDate=${endDate}`, {headers});
  }

}
