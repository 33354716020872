import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, delay, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreacionLandingService {
  private imagesSource = new BehaviorSubject<SafeUrl>([]);
  images$ = this.imagesSource.asObservable();

  private imagesSource2 = new BehaviorSubject<SafeUrl>([]);
  images2$ = this.imagesSource2.asObservable();

  private preguntasRespuestasSource = new BehaviorSubject<any[]>([]);
  preguntasRespuestas$ = this.preguntasRespuestasSource.asObservable();

  constructor() { }


  private queObtendrasSource = new BehaviorSubject<{ name: string }[]>([]);
  queObtendras$ = this.queObtendrasSource.asObservable();

  updateQueObtendras(queObtendras: { name: string }[]) {
    this.queObtendrasSource.next(queObtendras);
  }

  addImage(image: SafeUrl): void {
    const currentImages = this.imagesSource.value;
    const updatedImages = [...(currentImages as SafeUrl[]), image];
    this.imagesSource.next(updatedImages);
    
  }
  removeImage(index: number): void {
    const currentImages = this.imagesSource.value;
    if (index >= 0 && index < (currentImages as SafeUrl[]).length) {
      (currentImages as SafeUrl[]).splice(index, 1);
      this.imagesSource.next([...(currentImages as SafeUrl[])]);
    }
  }
  getImages(): Observable<SafeUrl>{
    return this.images$;
  }

  addImage2(image: SafeUrl): void {
    const currentImages = this.imagesSource2.value;
    const updatedImages = [...(currentImages as SafeUrl[]), image];
    this.imagesSource2.next(updatedImages);
  }

  getImages2(): Observable<SafeUrl>{
    return this.images2$;
  }

  removeImage2(index: number): void {
    const currentImages = this.imagesSource2.value;
    if (index >= 0 && index < (currentImages as SafeUrl[]).length) {
      (currentImages as SafeUrl[]).splice(index, 1);
      this.imagesSource2.next([...(currentImages as SafeUrl[])]);
    }
  }
  
  setPreguntasRespuestas(changes: any[]): void {
    this.preguntasRespuestasSource.next(changes);
  }
  
  getPreguntasRespuestas(): Observable<any[]> {
    return this.preguntasRespuestas$;
  }

  

  // getImages(): Observable<any[]> {
  //   return this.images$;
  // }
}
